<template>
    <div class="h-100 overflow-y-auto">
        <div class="d-flex align-items-center justify-content-center h-100" v-if="loading">
            <b-spinner class="text-secondary" />
        </div>
        <div v-else-if="search">
            <div v-for="operator in operatorsSearch" :key="operator._id">
                <div class="fw-semibold text-purple hstack gap-2 darkenOnHover p-3 border-top" role="button" @click="handleOperatorClick(operator)">
                    <b-avatar class="bg-secondary text-white" size="3em" :src="operator.photoURL" />
                    {{ operator.name }}
                </div>
            </div>
        </div>
        <div v-else>
            <div v-for="operator in operators" :key="operator._id">
                <div class="hstack justify-content-between gap-2 darkenOnHover p-3 border-top" role="button" @click="handleOperatorClick(operator)">
                    <div class="hstack gap-2">
                        <div class="position-relative">
                            <b-avatar class="bg-secondary text-white" size="3em" :src="operator.photoURL" />
                            <div class="position-absolute top-0 end-0 rounded-pill bg-green" style="height: .75em; width: .75em;" v-b-tooltip="{customClass: 'mt-5 top-0', variant: 'green'}" title="Online" v-if="operator.status"></div>
                            <div class="position-absolute top-0 end-0 rounded-pill bg-danger" style="height: .75em; width: .75em;" v-b-tooltip="{customClass: 'mt-5 top-0', variant: 'danger'}" title="Offline" v-else></div>
                        </div>
                        <div class="d-flex flex-column justify-content-center">
                            <div class="fw-semibold text-purple">
                                {{ operator.name }}
                            </div>
                            <div>
                                {{ operator.lastMessage }}
                            </div>
                        </div>
                    </div>
                    <div>
                        <span class="bg-primary text-white rounded-pill px-2 d-inline-block" v-if="operator.unreadCount">
                            {{ operator.unreadCount }}
                        </span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import api from '../services/apiService.js'

export default {
    props: [
        'socket',
        'user',
        'operator',
        'timestampDiff',
        'mediaURL',
        'search',
        'internalNewMessage'
    ],
    data() {
        return {
            chats: [],
            operators: [],
            operatorsSearch: [],
            loading: false,
        }
    },
    mounted() {
        this.init()
    },
    watch: {
        "search": {
            handler(newSearch) {
                this.searchOperators(newSearch)
            }
        },
        "internalNewMessage": {
            async handler(newMessage) {
                if (!newMessage) return

                if (newMessage.checkChat && newMessage.operatorIds.includes(this.operator._id) && newMessage.operatorId !== this.operator._id) {
                    const operatorIndex = this.operators.findIndex(el => el._id === newMessage.operatorId)
                    const operator = this.operators[operatorIndex]

                    if (operatorIndex !== -1) {
                        this.sendBrowserNotification({ ...newMessage, operator})
                        this.playAudio('https://firebasestorage.googleapis.com/v0/b/gotalk-app.appspot.com/o/assets%2Fmixkit-message-pop-alert-2354.mp3?alt=media&token=e76c575b-4ce7-4e57-8408-834229b653bf')
                        this.$set(this.operators, operatorIndex, {
                            ...this.operators[operatorIndex],
                            unreadCount: operator.unreadCount ? operator.unreadCount + 1 : 1,
                            lastMessage: newMessage.body
                        })
                    }
                } else {
                    const operatorIndex = this.operators.findIndex(el => el._id === newMessage.operatorId)

                    if (operatorIndex !== -1) {
                        this.$set(this.operators, operatorIndex, {
                            ...this.operators[operatorIndex],
                            lastMessage: newMessage.body
                        })
                    }
                }
            }
        }
    },
    methods: {
        init() {
            this.socketOnEvents()
            this.rootEvents()
            this.getOperators()
        },
        async getOperators() {
            if (!this.operators?.length) {
                this.operators = []
    
                const resp = await api.getOperators(this.user.channelId)
                if (resp.statusCode === 200) {
                    this.operators = resp.operators.filter(el => !el.isGhostOperator && el._id !== this.user.roleId)
                    
                    for (const operator of this.operators) {
                        this.socket.emit('get_operator_status', operator._id)
                        this.socket.emit('get_chat_unread_count', [operator._id, this.operator._id].sort())
                        this.socket.emit('get_chat_last_message', [operator._id, this.operator._id].sort())
                    }
                }

                return this.operators
            }

            return this.operators
        },
        async searchOperators(search = "") {
            this.loading = true

            this.operatorsSearch = []

            const operators = await this.getOperators()
            if (operators) {
                this.operatorsSearch = search ? operators.filter(el => !el.isGhostOperator &&  el.name.toLowerCase().includes(search.toLowerCase()) && el._id !== this.user.roleId) : operators.filter(el => !el.isGhostOperator && el._id !== this.user.roleId)
            }
            
            this.loading = false
        },
        handleOperatorClick(operator) {
            if (operator?.unreadCount) operator.unreadCount = 0

            // if (!operator.status) {
            //     return this.$root.$emit('msg', { text: "Operador offline!", success: false })
            // }

            this.$emit('loadOperatorChat', operator._id)

            if(window.innerWidth <= 425) {
                const col1 = document.getElementById('col1')
                if(col1)
                    col1.style.flex = '0'

                const col2 = document.getElementById('col2')
                if(col2)
                    col2.style.flex = '1'

                setTimeout(() => {
                    const mbButtonCol2 = document.getElementById('mobile-back-button-internal')
    
                    if(mbButtonCol2)
                        mbButtonCol2.style.display = 'inline-block'
                }, 500)

            }
        },
        sortOperators(operators) {
            return operators.sort((a, b) => {
                if (a.status && !b.status) return -1
                if (!a.status && b.status) return 1
                if (a.status && b.status) {
                    if (a.lastMessage && !b.lastMessage) return -1
                    if (!a.lastMessage && b.lastMessage) return 1
                } else {
                    if (a.lastMessage && !b.lastMessage) return -1
                    if (!a.lastMessage && b.lastMessage) return 1
                }
                return 0
            })
        },
        showNotification(messageText,contactName,contactPhotoURL) {
            if(document.hasFocus())
                return

            const notification = new Notification(contactName, { body: messageText, icon: contactPhotoURL || "https://firebasestorage.googleapis.com/v0/b/notifiqueai-app.appspot.com/o/assets%2Fdefault.jpg?alt=media&token=73be2b6f-a8a3-4407-b079-ab3918d37d8c" })
            notification.onclick = () => { 
                notification.close()
                window.parent.focus()
            }
        },
        requestNotificationPermission() {
            if (!("Notification" in window))
                alert("Esse navegador não suporta notificações!")
            if (Notification.permission == "denied") {
                alert("Notificações estão bloqueadas pelo seu navegador! \nVocê pode desbloqueá-la nas configurações do seu navegador.")
            }
            Notification.requestPermission((permission) => {
               this.browserNotification = permission
                if (permission === "granted") {
                    this.$bvModal.show("notificationConfig")
                }
            })
        },
        requestAndShowPermission() {
            Notification.requestPermission(function (permission) {
                if (permission === "granted") {
                    this.$bvModal.show("notificationConfig")
                    this.showNotification()
                }
            });
        },
        sendBrowserNotification({ operator, ...message }) {
            console.log({ message })
            if ('Notification' in window) {
                let text
                switch(message.type) {
                    case 'text':
                    case 'chat':
                        text = message.body
                    break
                    case 'video':
                        text = "Vídeo"
                    break
                    case 'image':
                        text = "Ímagem"
                    break
                    case 'audio':
                        text = "Áudio"
                    break
                    case 'location':
                        text = "Localização"
                    break
                    case 'vcard':
                        text = "Contato"
                    break
                    case 'vcardArray':
                        text = "Contatos"
                    break
                    case 'document':
                    case 'application':
                        text = "Documento"
                    break
                    case 'sticker':
                        text = "Sticker"
                    break
                    default:
                        return
                }
    
                const permission = Notification.permission

                if(permission === "granted")
                    this.showNotification(text, `Chat Interno: ${operator.nickname || operator.name}`)
                else if(permission === "default")
                    this.requestAndShowPermission()
            }
        },
        playAudio(url) {
            const audio = new Audio(url)
            audio.play()
        },
        socketOnEvents() {
            this.socket.on('get_operator_status', ({ operatorId, status}) => {
                if (typeof status !== 'boolean') {
                    status = status === 'true' ? true : false
                }

                const operator = this.operators.find(el => el._id === operatorId)
                if (operator) {
                    operator.status = status
                }

                if (this.search) {
                    const operatorSearch = this.operatorsSearch.find(el => el._id === operatorId)
                    if (operatorSearch) {
                        operatorSearch.status = status
                    }

                    this.operatorsSearch = this.sortOperators(this.operatorsSearch)
                }

                this.operators = this.sortOperators(this.operators)
            })

            this.socket.on('get_chat_last_message', ({ operatorIds, body, type }) => {
                const operator = this.operators.find(el => operatorIds.includes(el._id))

                if (type !== 'text' && type !== 'chat') {
                    switch (type) {
                        case 'image':
                        case 'sticker':
                            body = 'Imagem'
                            break
                        case 'video':
                            body = 'Vídeo'
                            break
                        case 'audio':
                        case 'ptt':
                            body = 'Áudio'
                            break
                        case 'location':
                            body = 'Localização'
                            break
                        case 'vcard':
                        case 'vcardArray':
                            body = 'Contato'
                            break
                        case 'file':
                        case 'document':
                        case 'application':
                            body = 'Arquivo'
                            break
                        default:
                            body = '- - - -'
                            break
                    }
                }

                if (operator) {
                    operator.lastMessage = body
                }

                if (this.search) {
                    const operatorSearch = this.operatorsSearch.find(el => operatorIds.includes(el._id))
                    if (operatorSearch) {
                        operatorSearch.lastMessage = body
                    }

                    this.operatorsSearch = this.sortOperators(this.operatorsSearch)
                }

                this.operators = this.sortOperators(this.operators)
            })

            this.socket.on('get_chat_unread_count', ({ operatorIds, unreadCount }) => {
                const operator = this.operators.find(el => operatorIds.includes(el._id))

                if (operator) {
                    this.$set(operator, "unreadCount", unreadCount)
                }
                
                if (this.search) {
                    const operatorSearch = this.operatorsSearch.find(el => operatorIds.includes(el._id))
                    if (operatorSearch) {
                        this.$set(operatorSearch, "unreadCount", unreadCount)
                    }
                }
            })
        },
        rootEvents() {
            this.$root.$on('last_message', (data) => {
                const operatorIndex = this.operators.findIndex(el => data.operatorIds.includes(el._id))

                if (operatorIndex !== -1) {
                    this.$set(this.operators, operatorIndex, {
                        ...this.operators[operatorIndex],
                        lastMessage: data.body
                    })
                }
            })
        }
    }
}
</script>