<template>
    <div>
        <b-row class="m-0 text-purple">
            <b-col class="p-0">
                <div class="bg-white rounded">
                    <div class="d-flex align-items-center text-purple fw-bold fs-5 border-bottom p-3">
                        <span class="cardnumber rounded-circle">03</span>
                        Personalizar Menu
                    </div>
                    <div class="p-3">
                        <!-- <b-form-group label-class="fw-bold" label="Selecionar Menu: ">
                            <b-form-select class="w-100 rounded" :options="selectMenuOptions" v-model="selectedMenu" @change="checkAction(selectedMenu)"></b-form-select>
                        </b-form-group> -->
                        <b-form-group label-class="fw-bold" label="Selecionar Menu:">
                            <div class="position-relative">
                                <div class="border rounded w-100 text-grey py-1 px-3 d-flex justify-content-between" style="border-color: #eee !important" role="button" v-b-toggle.collapseOptionSelect>
                                    <span v-if="selectedMenu">
                                        {{ selectedMenu.option ||  selectedMenu.preTitle }} - {{ selectedMenu.textOption || selectedMenu.title }}
                                    </span>
                                    <span v-else>
                                        Selecione um menu
                                    </span>
                                    <span>
                                        <b-icon class="text-dark" icon="chevron-down" style="margin-right: -0.9em" font-scale="0.75"></b-icon>
                                    </span>
                                </div>
                                <b-collapse id="collapseOptionSelect" ref="collapseOptionSelect" class="position-absolute bg-white border w-100 rounded" style="z-index: 1" v-model="collapseOptionSelectVisible" v-if="selectMenuOptions">
                                    <div v-for="item in selectMenuOptions" :key="item.id">
                                        <tree-select-options 
                                            :submenus="item.submenus"
                                            :text="item.text"
                                            :value="item.value"
                                            :depth="0"
                                        /> 
                                    </div>
                                    <div class="w-100 h-100 position-fixed top-0 end-0 start-0 bottom-0" role="window" style="z-index: -1" v-b-toggle.collapseOptionSelect></div>
                                </b-collapse>
                            </div>
                        </b-form-group>
                    </div>
                </div>
                <div v-if="selectedMenu">
                    <div class="bg-white rounded mt-3">
                        <div class="d-flex align-items-center justify-content-between p-3 border-bottom">
                            <div class="d-flex align-items-center text-purple fw-bold fs-5">
                                <span class="cardnumber rounded-circle">04</span>
                                    Direcionar para Atendimento ou Submenu
                            </div>
                        </div>
                        <div class="vstack gap-3 p-3">
                            <div class="vstack gap-2" v-if="user.channelConfig?.modules?.chatbotForms">
                                <b-form-checkbox class="hstack gap-2" v-model="selectedMenu.formsEnabled" @change="formsEnabledChange">
                                    Ativar módulo de Formulário
                                </b-form-checkbox>
                                <div class="vstack gap-3 ms-4" v-if="selectedMenu.formsEnabled && selectedMenu.forms">
                                    <div class="hstack justify-content-between">
                                        <div class="fw-semibold">
                                            Formulários:
                                        </div>
                                        <div v-b-tooltip.hover.bottom="{ customClass: 'top-0', boundary: 'document', variant: 'purple' }" title="Adicionar">
                                            <b-iconstack class="darkenTextOnHover" font-scale="1.5" role="button" @click="addForm">
                                                <b-icon stacked icon="circle-fill"></b-icon>
                                                <b-icon stacked icon="plus" variant="light"></b-icon>
                                            </b-iconstack>
                                        </div>
                                    </div>
                                    <div class="vstack gap-3">
                                        <span class="vstack gap-1 border rounded p-2 position-relative" v-for="(form, index) in selectedMenu.forms" :key="form.id">
                                            <b-form-group label-class="fw-bold" label="Texto:">
                                                <b-form-textarea
                                                v-model="form.text"
                                                placeholder="Ex.: Digite seu CPF"
                                                rows="3"
                                                no-resize
                                                ></b-form-textarea>
                                                <div class="d-inline position-absolute top-0 mt-2 me-2 end-0 darkenTextOnHover" v-b-tooltip.hover.bottom="{ customClass: 'top-0', boundary: 'document', variant: 'danger' }" title="Excluir" @click="removeForm(index)">
                                                    <b-icon icon="trash-fill" variant="danger" font-scale="1.25"></b-icon>
                                                </div>
                                            </b-form-group>
                                            <b-form-group label-class="fw-bold" label="Enriquecer campo:">
                                                <b-form-select class="w-100 rounded" v-model="form.field">
                                                    <b-form-select-option :value="undefined">Nenhum</b-form-select-option>
                                                    <b-form-select-option value="city">Cidade</b-form-select-option>
                                                    <b-form-select-option value="document">CPF/CNPJ</b-form-select-option>
                                                    <b-form-select-option value="email">Email</b-form-select-option>
                                                    <b-form-select-option value="state">Estado</b-form-select-option>
                                                    <b-form-select-option value="name">Nome</b-form-select-option>
                                                </b-form-select>
                                            </b-form-group>
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <b-form-group label-class="fw-bold" label="Direcionar para:">
                                <b-form-select class="w-100 rounded" v-model="selectedMenu.actionType" @change="checkAction(selectedMenu)">
                                    <b-form-select-option value="redirectDepartment">Departamento</b-form-select-option>
                                    <b-form-select-option value="redirectOperator">Operador</b-form-select-option>
                                    <b-form-select-option value="submenu">Submenu - Texto</b-form-select-option>
                                    <b-form-select-option value="list" v-if="user.channel?.apiType == 'cloud' || user.channel?.apiType == 'gupshup'">Submenu - Lista Interativa</b-form-select-option>
                                    <b-form-select-option value="button" v-if="(user.channel?.apiType == 'cloud' || user.channel?.apiType == 'gupshup') || user.roleId == '63ecf3f5814162f04056d9c7' || user.roleId == '634b2430919b7486d9cc3839'">Submenu - Botões Interativos</b-form-select-option>
                                    <b-form-select-option value="previousMenu">Voltar para menu anterior</b-form-select-option>
                                    <b-form-select-option value="finish">Finalizar Atendimento</b-form-select-option>
                                    <b-form-select-option value="integration_url">Integração</b-form-select-option>
                                </b-form-select>
                            </b-form-group>
                            <div class="vstack gap-2" v-if="['redirectDepartment','redirectOperator'].includes(selectedMenu.action)">
                                <b-form-group class="mt-2" label-class="fw-bold" :label="`Selecione o ${ selectedMenu.action == 'redirectDepartment' ? 'Departamento' : 'Operador' }:`">
                                    <b-form-select class="w-100 rounded" v-model="selectedMenu.redirectId" v-if="selectedMenu.action == 'redirectDepartment'">
                                        <b-form-select-option :value="dep._id" v-for="dep in departments" :key="dep._id">{{ dep.name }}</b-form-select-option>
                                    </b-form-select>
                                    <b-form-select class="w-100 rounded" v-model="selectedMenu.redirectId" v-else-if="selectedMenu.action == 'redirectOperator'">
                                        <b-form-select-option :value="op._id" v-for="op in operators" :key="op._id">{{ op.name }}</b-form-select-option>
                                    </b-form-select>
                                </b-form-group>
                                <div class="vstack gap-2" v-if="user.channelConfig?.modules?.backOffice && user.channelConfig?.enableCustomerPortfolio">
                                    <b-form-checkbox class="hstack gap-2" v-model="selectedMenu.backOfficeEnabled">
                                        Ativar módulo de BackOffice
                                    </b-form-checkbox>
                                    <div class="vstack gap-2 ms-3" v-if="selectedMenu.backOfficeEnabled">
                                        <b-form-group label-class="fw-bold" label="Direcionar para:">
                                            <b-form-select class="w-100 rounded" v-model="selectedMenu.backOffice.action" >
                                                <b-form-select-option value="redirectDepartment">Departamento</b-form-select-option>
                                                <b-form-select-option value="redirectOperator">Operador</b-form-select-option>
                                            </b-form-select>
                                        </b-form-group>
                                        <b-form-group class="mt-2" label-class="fw-bold" :label="`Selecione o ${ selectedMenu.backOffice.action == 'redirectDepartment' ? 'Departamento' : 'Operador' }:`" v-if="['redirectDepartment','redirectOperator'].includes(selectedMenu.backOffice.action)">
                                            <b-form-select class="w-100 rounded" v-model="selectedMenu.backOffice.redirectId" v-if="selectedMenu.backOffice.action == 'redirectDepartment'">
                                                <b-form-select-option :value="dep._id" v-for="dep in departments" :key="dep._id">{{ dep.name }}</b-form-select-option>
                                            </b-form-select>
                                            <b-form-select class="w-100 rounded" v-model="selectedMenu.backOffice.redirectId" v-else-if="selectedMenu.backOffice.action == 'redirectOperator'">
                                                <b-form-select-option :value="op._id" v-for="op in operators" :key="op._id">{{ op.name }}</b-form-select-option>
                                            </b-form-select>
                                        </b-form-group>
                                    </div>
                                </div>
                            </div>
                            <div class="vstack gap-2" v-else-if="selectedMenu.action === 'integration_url' && selectedMenu.integration">
                                <b-form-group label="URI Integração:" label-class="fw-bold">
                                    <b-form-input class="px-3" v-model="selectedMenu.integration.url" required />
                                </b-form-group>
                                <b-form-group label="Fallback:" label-class="fw-bold">
                                    <div class="smaller-text text-secondary mb-1">
                                        O Fallback acontece quando a Integração retorna qualquer tipo de erro.
                                    </div>
                                    <b-form-select class="w-100 rounded" v-model="selectedMenu.integration.fallbackType" @change="refreshSelectedMenu">
                                        <b-form-select-option :value="null">Nenhum</b-form-select-option>
                                        <b-form-select-option value="redirectDepartment">Departamento</b-form-select-option>
                                        <b-form-select-option value="redirectOperator">Operador</b-form-select-option>
                                        <b-form-select-option value="finish">Finalizar Atendimento</b-form-select-option>
                                    </b-form-select>
                                    <b-form-group class="mt-2" label-class="fw-bold" :label="`Selecione o ${ selectedMenu.integration.fallbackType === 'redirectDepartment' ? 'Departamento' : 'Operador' }:`" v-if="['redirectDepartment','redirectOperator'].includes(selectedMenu.integration.fallbackType)">
                                    <b-form-select class="w-100 rounded" v-model="selectedMenu.integration.fallbackId" v-if="selectedMenu.integration.fallbackType === 'redirectDepartment'">
                                        <b-form-select-option :value="dep._id" v-for="dep in departments" :key="dep._id">{{ dep.name }}</b-form-select-option>
                                    </b-form-select>
                                    <b-form-select class="w-100 rounded" v-model="selectedMenu.integration.fallbackId" v-else-if="selectedMenu.integration.fallbackType === 'redirectOperator'">
                                        <b-form-select-option :value="op._id" v-for="op in operators" :key="op._id">{{ op.name }}</b-form-select-option>
                                    </b-form-select>
                                </b-form-group>
                                </b-form-group>
                            </div>
                        </div>
                    </div>
                    <div class="bg-white rounded mt-3" v-if="(selectedMenu.action === 'submenu' || selectedMenu.action === 'integration_url') && submenu">
                        <div class="d-flex align-items-center text-purple fw-bold fs-5 border-bottom p-3">
                            <span class="cardnumber rounded-circle">05</span>
                            Texto e Mídias
                        </div>
                        <div class="p-3" v-if="['list','button'].includes(selectedMenu.actionType)">
                            <div class="addimage mb-1" v-if="submenu.imageEnabled">
                                <b-form-file id="menuImgUpload" style="display:none" accept="image/*" @input="selectMenuImage"></b-form-file>
                                <label for='menuImgUpload' class="w-100" v-if="submenu.media">
                                    <div class="cardpresentationimg rounded" v-if="!submenu.media[0]?.url">
                                        <div class="d-flex flex-column align-items-center">
                                            <b-icon class="cardpresentationicon" icon="image"></b-icon>
                                            <div class="cardpresentationtext">Adicionar Imagem</div>
                                        </div>
                                    </div>
                                    <div style="max-height:325px;" role="button" v-else>
                                        <b-img class="rounded" style="max-height: 325px; width: 100%" :src="submenu.media[0].url"></b-img>
                                    </div>
                                </label>
                            </div>
                            <div class="invisible" style="height:0">{{ submenu }}</div>
                            <div class="cardpresentationcontenttop d-flex justify-content-between align-items-center" v-if="selectedMenu?.actionType == 'list'">
                                <b-form-checkbox class="d-flex align-items-center" @change="toggleImageEnabledList">
                                    <div class="ms-2">Imagem</div>
                                </b-form-checkbox>
                            </div>
                            <div class="position-relative">
                                <b-form-group label="Cabeçalho:" label-class="fw-bold" v-if="['button','list'].includes(selectedMenu.actionType) && submenu.header">
                                    <b-form-radio-group class="d-flex mb-1" v-model="submenu.header.type" v-if="selectedMenu.actionType == 'button'">
                                        <b-form-radio class="d-inline-flex me-3" value="text">
                                            <div class="ms-2">Texto</div>
                                        </b-form-radio>
                                        <b-form-radio class="d-inline-flex me-3" value="image">
                                            <div class="ms-2">Imagem</div>
                                        </b-form-radio>
                                    </b-form-radio-group>
                                    <div class="position-relative" v-if="selectedMenu.actionType == 'list' || submenu.header?.type == 'text'">
                                        <b-form-input class="px-4" v-model="submenu.header.text" placeholder="Escreva seu texto de cabeçalho" maxlength="60" />
                                        <b-icon class="toggle-emoji darkenTextOnHover mb-1" @click="toggleDialogEmojiHeader" icon="emoji-smile" font-scale="1.25" ></b-icon>
                                        <VEmojiPicker @select="selectEmojiHeader" lang="pt-BR" v-show="showDialogHeader" :i18n="i18n" v-if="itemSelected" />
                                    </div>
                                    <div class="text-danger small-text mt-1" v-if="submenu.header?.text?.length >= 60 && (selectedMenu.actionType == 'list' || submenu.header.type == 'text')">
                                        O limite de caracteres (60) foi atingido!
                                    </div>
                                    <div class="addimage" v-else-if="submenu.header.type == 'image'">
                                        <b-form-file id="menuImgUpload" style="display:none" accept="image/*" @input="selectHeaderImage"></b-form-file>
                                        <label for='menuImgUpload' class="w-100">
                                            <div class="cardpresentationimg rounded" v-if="!submenu.header.link">
                                                <div class="d-flex flex-column align-items-center">
                                                    <b-icon class="cardpresentationicon" icon="image"></b-icon>
                                                    <div class="cardpresentationtext">Adicionar Imagem</div>
                                                </div>
                                            </div>
                                            <div style="max-height:325px;" role="button" v-else>
                                                <b-img class="rounded" style="max-height: 325px; width: 100%" :src="submenu.header.link"></b-img>
                                            </div>
                                        </label>
                                    </div>
                                </b-form-group>
                            </div>
                            <div>
                                <div class="position-relative mt-2">
                                    <b-form-group label="Texto:" label-class="fw-bold" v-if="submenu.body">
                                        <b-form-textarea class="px-4" v-model="submenu.body.text" placeholder="Escreva seu texto" maxlength="1024" rows="5" max-rows="5" no-resize></b-form-textarea>
                                        <b-icon class="toggle-emoji darkenTextOnHover" @click="toggleDialogEmojiTextarea" icon="emoji-smile" font-scale="1.25" ></b-icon>
                                        <VEmojiPicker @select="selectEmojiTextarea" lang="pt-BR" v-show="showDialogTextarea" :i18n="i18n" />
                                    </b-form-group>
                                </div>
                                <div class="text-danger small-text mt-1" v-if="submenu.body.text?.length >= 1024">
                                    O limite de caracteres (1024) foi atingido!
                                </div>
                            </div>
                            <div class="position-relative mt-2">
                                <b-form-group label="Rodapé:" label-class="fw-bold" v-if="submenu.footer">
                                    <b-form-input class="px-4" v-model="submenu.footer.text" placeholder="Escreva seu texto de rodapé" maxlength="60" />
                                    <b-icon class="toggle-emoji darkenTextOnHover mb-1" @click="toggleDialogEmojiFooter" icon="emoji-smile" font-scale="1.25" ></b-icon>
                                    <VEmojiPicker @select="selectEmojiFooter" lang="pt-BR" v-show="showDialogFooter" :i18n="i18n" />
                                </b-form-group>
                            </div>
                            <div class="text-danger small-text mt-1" v-if="submenu.footer?.text?.length >= 60">
                                O limite de caracteres (60) foi atingido!
                            </div>
                            <div class="mt-2" v-if="selectedMenu.actionType == 'list'">
                                <b-form-group label="Texto do Botão:" label-class="fw-bold">
                                    <b-form-input class="px-4" v-model="submenu.action.button" maxlength="20" />
                                </b-form-group>
                            </div>
                            <div class="d-flex justify-content-between align-items-center mt-3">
                                <div>
                                    <!-- <b-form-checkbox class="cardpresentationcheckbox">Texto</b-form-checkbox> -->
                                </div>
                                <div>
                                    <!-- <b-button class="managemenubtngreen" v-b-modal.modal-evasivas>Evasivas</b-button> -->
                                    <b-button class="managemenubtnpink" v-b-modal.modal-tags>Tags</b-button>
                                </div>
                            </div>
                        </div>
                        <div class="p-3" v-else>
                            <div>
                                <b-form-checkbox v-model="submenu.textEnabled">
                                    <div class="ms-2">Texto</div>
                                </b-form-checkbox>
                                <div class="textareaemoji-container mt-1" v-if="submenu.textEnabled">
                                    <b-form-textarea class="px-4" :placeholder="selectedMenu.action === 'integration_url' ? 'Escreva seu texto\nObs.: Caso utilize texto ou mídia, o atendimento só será encaminhado para a URL de integração após resposta do contato.' : 'Escreva seu texto'" rows="5" max-rows="5" no-resize v-model="submenu.text" @input="setContentOrder('text')"></b-form-textarea>
                                    <b-button class="toggle-emoji darkenTextOnHover" @click="toggleDialogEmojiSubmenuTextarea"><b-icon icon="emoji-smile"></b-icon></b-button>
                                    <VEmojiPicker @select="selectEmojiSubmenuTextarea" lang="pt-BR" v-show="showDialogSubmenuTextarea" :i18n="i18n" />
                                </div>
                            </div>
                            <div class="mt-3">
                                <b-form-checkbox v-model="submenu.imageEnabled" @change="changeMedia">
                                    <div class="ms-2">Mídia</div>
                                </b-form-checkbox>
                                <b-form-group class="d-flex" v-if="submenu.imageEnabled">
                                    <b-form-radio-group class="d-flex" v-model="submenu.media[0].type" @change="submenu.media[0].url = ''">
                                        <b-form-radio class="me-3" value="image">
                                            <div class="ms-2">Imagem</div>
                                        </b-form-radio>
                                        <b-form-radio class="me-3" value="audio">
                                            <div class="ms-2">Aúdio</div>
                                        </b-form-radio>
                                        <b-form-radio class="me-3" value="video">
                                            <div class="ms-2">Vídeo</div>
                                        </b-form-radio>
                                        <b-form-radio value="file">
                                            <div class="ms-2">Arquivo</div>
                                        </b-form-radio>
                                    </b-form-radio-group>
                                </b-form-group>
                                <div class="addimage" v-if="submenu.imageEnabled && submenu.media[0]?.type">
                                    <b-form-file id="menuImgUpload" style="display:none" :accept="fileAccept(submenu.media[0].type)" @input="setMenuMedia"></b-form-file>
                                    <label class="w-100" for='menuImgUpload'>
                                        <div class="flex-column align-items-center align-self-center text-white bg-purple w-100 rounded mt-1" style="padding: 7em 0" role="button" v-if="!submenu.media[0].url">
                                            <div class="d-flex flex-column align-items-center w-100">
                                                <b-icon class="fs-3" icon="image"></b-icon>
                                                <div class="fs-6">Adicionar Mídia</div>
                                            </div>
                                        </div>
                                        <div class="mt-1" style="max-height: 300px;" role="button" v-else>
                                            <!-- <b-img style="max-height: 325px; width: 100%" :src="menu.media[0].url"></b-img> -->
                                            <img class="w-100 h-auto rounded" style="max-height: 300px" :src="submenu.media[0].url" v-if="submenu.media[0].url && submenu.media[0].type=='image'">
                                            <video class="w-100" style="max-height: 300px" :src="submenu.media[0].url" v-else-if="submenu.media[0].url && submenu.media[0].type=='video'"></video>
                                            <div class="p-3 bg-white d-flex flex-column align-items-center justify-content-center w-100" style="max-height: 300px" v-else-if="submenu.media[0].url && submenu.media[0].type=='audio'">
                                                <b-icon class="mb-3" font-scale="4" icon="file-earmark-music"></b-icon>
                                                {{ submenu.media[0].filename }}
                                            </div>
                                            <div class="p-3 bg-white d-flex flex-column align-items-center justify-content-center w-100 h-100" v-else-if="submenu.media[0].url && submenu.media[0].type=='file'">
                                                <b-icon
                                                    class="mb-3" 
                                                    :icon="(submenu.media[0].ext =='csv' || (submenu.media[0].ext =='xlsx' || submenu.media[0].ext =='txt')) ? 'file-earmark-spreadsheet-fill' : 'file-earmark-fill'" 
                                                    :class="{ 'text-success': (submenu.media[0].ext =='csv' || submenu.media[0].ext =='xlsx'), 'text-danger': submenu.media[0].ext =='pdf'}" 
                                                    font-scale="4"
                                                ></b-icon>
                                                {{ submenu.media[0].filename }}
                                            </div>
                                        </div>
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </b-col>
            <b-col class="p-0 ps-3">
                <div v-if="selectedMenu">
                    <div class="bg-white rounded mb-3" v-if="selectedMenu.action == 'submenu' && submenu && !['list','button'].includes(selectedMenu.actionType)">
                        <div class="d-flex align-items-center text-purple fw-bold fs-5 border-bottom p-3">
                            <span class="cardnumber rounded-circle">06</span>
                            Ordenação do Conteúdo
                        </div>
                        <div class="p-3">
                            <draggable tag="ul" :list="submenu.contentOrder" class="list-group" handle=".handle" v-if="submenu.contentOrder?.length">
                                <li
                                class="d-flex mb-3 w-100"
                                v-for="item in submenu.contentOrder"
                                :key="item"
                                >
                                    <!-- <b-icon font-scale="1.75" icon="arrows-move" class="handle text-secondary"></b-icon> -->
                                    <div class="border rounded p-2 d-flex align-items-center w-100 handle" role="button">
                                        <b-icon icon="arrows-move" font-scale="1.5"></b-icon>
                                        <div class="ms-2">{{ item == 'text' ? 'Texto' : 'Mídia' }}</div>
                                    </div>
                                </li>
                            </draggable>
                            <div class="border rounded p-2 d-flex align-items-center handle" role="button" v-else>
                                <b-icon icon="arrows-move" font-scale="1.5"></b-icon>
                                <div class="ms-2">Texto</div>
                            </div>
                        </div>
                    </div>
                    <div class="bg-white rounded" v-if="['redirectDepartment','redirectOperator','submenu'].includes(selectedMenu.action) && !['list','button'].includes(selectedMenu.actionType) && !selectedMenu.preTitle">
                        <div class="d-flex align-items-center text-purple fw-bold fs-5 border-bottom p-3">
                            <span class="cardnumber rounded-circle">{{ selectedMenu.action == 'submenu' ? '07' : '05'  }}</span>
                            Palavras-chave
                        </div>
                        <div class="p-3">
                            Utilize o campo abaixo para que o contato possa identificar o menu com palavras-chave
                                <b-form-tags 
                                    class="inputKeywords border-grey mt-3" 
                                    add-button-text="Adicionar"
                                    add-button-variant="primary"
                                    duplicate-tag-text="Tag duplicada"
                                    limit-tags-text="Limite atingido: 5"
                                    :limit="5"
                                    tag-remove-label="Remover Tag"
                                    tag-removed-label="Tag removida"
                                    placeholder="Adicionar Tag"
                                    v-model="selectedMenu.keywords"
                                ></b-form-tags>
                        </div>
                    </div>
                    <div class="bg-white rounded mt-3" v-if="selectedMenu.action == 'submenu'  && !['list','button'].includes(selectedMenu.actionType)">
                        <div class="d-flex align-items-center justify-content-between p-3 border-bottom">
                            <div class="d-flex align-items-center text-purple fw-bold fs-5">
                                <span class="cardnumber rounded-circle">08</span>
                                Incluir Submenu
                            </div>
                            <b-button class="managemenubtnpink h-fit" v-b-modal.modal-tags>Tags</b-button>
                        </div>
                        <div class="p-3">
                            <b-button variant="green" class="w-100 text-white mb-3" @click="addMenu">Adicionar</b-button>
                            <draggable :list="submenu.menus" tag="ul" class="list-group" handle=".handle" @end="checkMove" v-if="submenu">
                                <li
                                class="d-flex mb-3"
                                v-for="(item) in submenu.menus"
                                :key="item.option"
                                >
                                    <b-form-input class="cardmainmenuitem cardmainmenunumber rounded" :placeholder="item.option" disabled></b-form-input>
                                    <div class="me-3">
                                        <!-- <b-button class="cardmainmenuicon h-100 p-0" @click.prevent="selectItem(item,menu,$event)">
                                            <span v-if="item.icone">
                                                {{item.icone}}
                                            </span>
                                            <span v-else>
                                                Ícone
                                            </span>
                                        </b-button>  -->
                                        <div class="cardmainmenuicon rounded d-flex h-100" v-if="item.icone">
                                            <b-button class=" h-100 p-0 flex-grow-1 rounded-end-0" variant="green3"  @click.prevent="selectItem(item,menu,$event)">
                                                {{item.icone}}
                                            </b-button>
                                            <b-button class="h-100 p-0 flex-grow-1 rounded-start-0" variant="danger" @click.prevent="item.icone = ''">
                                                <b-icon icon="x"></b-icon>
                                            </b-button>
                                        </div>
                                        <b-button class="cardmainmenuicon h-100 p-0" @click.prevent="selectItem(item,submenu,$event)" v-else>
                                            Ícone
                                        </b-button>
                                        <VEmojiPicker class="iconemojipicker" @select="selectEmojiIcon" lang="pt-BR" v-show="showDialogIcon" :i18n="i18n" v-if="selectedMenu" />
                                    </div>
                                    <b-input-group class="cardmainmenuitem">
                                        <template #append>
                                            <div class="handle bg-grey h-100 d-flex align-items-center px-2 rounded-end" v-b-tooltip.bottom="{ customClass: 'tooltip-orderChange' }" title="Alterar Ordem" role="button">
                                                <b-icon font-scale="1.75" icon="arrows-move" class="text-secondary"></b-icon>
                                            </div>
                                        </template>
                                        <b-form-input class="cardmainmenuitem cardmainmenuname rounded-start" v-model="item.textOption"></b-form-input>
                                    </b-input-group>
                                    <!-- <b-button class="cardmainmenuitem cardmainmenueyebtn"><b-icon icon="eye"></b-icon></b-button> -->
                                    <!-- <b-button class="cardmainmenuitem cardmainmenueditbtn rounded" v-b-tooltip.bottom="{ customClass: 'tooltip-edit' }" title="Editar" v-b-modal="`modal-editMenu`" ><b-icon icon="pencil-square"></b-icon></b-button> -->
                                    <b-button class="cardmainmenuitem cardmainmenuremovebtn rounded" v-b-tooltip.bottom="{ customClass: 'tooltip-remove' }" title="Remover" v-b-modal.modal-removeMenu @click.prevent="selectItemToDelete(item)"><b-icon icon="trash"></b-icon></b-button>
                                </li>
                            </draggable>
                        </div>
                    </div>
                    <div class="bg-white rounded" v-if="selectedMenu.actionType == 'list'">
                        <div class="d-flex align-items-center justify-content-between p-3 border-bottom">
                            <div class="d-flex align-items-center text-purple fw-bold fs-5">
                                <span class="cardnumber rounded-circle">06</span>
                                Sessões <div class="invisible" style="height:0">{{ submenu }}</div>
                            </div>
                            <b-button class="managemenubtnpink h-fit" v-b-modal.modal-tags>Tags</b-button>
                        </div>
                        <div class="vstack gap-3 p-3">
                            <b-button variant="green" class="w-100 text-white mb-3" @click="addSection">Adicionar</b-button>
                            <draggable :list="submenu.action.sections" tag="ul" class="list-group vstack gap-3" handle=".handle" v-if="submenu?.action?.sections">
                                <li
                                class="vstack"
                                v-for="(item) in submenu.action.sections"
                                :key="item._id"
                                >
                                    <div class="hstack gap-2">
                                        <b-input-group>
                                            <template #append>
                                                <b-button class="handle rounded-0 rounded-end" v-b-tooltip.bottom title="Alterar Ordem">
                                                    <b-icon icon="arrows-move"></b-icon>
                                                </b-button>
                                            </template>
                                            <b-form-input maxlength="20" v-model="item.title"></b-form-input>
                                        </b-input-group>
                                        <b-button variant="green" class="text-white" v-b-tooltip.bottom="{ customClass: 'tooltip-edit' }" title="Editar" v-b-modal.modalEditSection @click.prevent="selectSection(item)"><b-icon icon="pencil-square"></b-icon></b-button>
                                        <b-button variant="red" class="text-white" v-b-tooltip.bottom="{ customClass: 'tooltip-remove' }" title="Remover" v-b-modal.modal-removeMenu @click.prevent="selectItemToDelete(item)"><b-icon icon="trash"></b-icon></b-button>
                                    </div>
                                    <div class="text-danger small-text" v-if="item.title?.length >= 20">
                                        O limite de caracteres (20) foi atingido!
                                    </div>
                                </li>
                            </draggable>
                        </div>
                    </div>
                    <div class="bg-white rounded" v-if="selectedMenu.actionType == 'button'">
                        <div class="d-flex align-items-center justify-content-between p-3 border-bottom">
                            <div class="d-flex align-items-center text-purple fw-bold fs-5">
                                <span class="cardnumber rounded-circle">06</span>
                                Botões <div class="invisible" style="height:0">{{ submenu }}</div>
                            </div>
                            <b-button class="managemenubtnpink h-fit" v-b-modal.modal-tags>Tags</b-button>
                        </div>
                        <div class="vstack gap-3 p-3">
                            <b-button variant="green" class="w-100 text-white" @click="addButton">Adicionar</b-button>
                            <draggable :list="submenu.action.buttons" tag="ul" class="list-group vstack gap-3" handle=".handle" v-if="submenu">
                                <li
                                class="vstack gap-1"
                                v-for="(item) in submenu.action.buttons"
                                :key="item._id"
                                >
                                    <div class="hstack gap-2">
                                        <b-input-group>
                                            <template #append>
                                                <b-button class="handle rounded-0 rounded-end" v-b-tooltip.bottom title="Alterar Ordem">
                                                    <b-icon icon="arrows-move"></b-icon>
                                                </b-button>
                                            </template>
                                            <b-form-input maxlength="20" v-model="item.reply.title"></b-form-input>
                                        </b-input-group>
                                        <b-button variant="red" class="text-white" v-b-tooltip.bottom="{ customClass: 'tooltip-remove' }" title="Remover" v-b-modal.modal-removeMenu @click.prevent="selectItemToDelete(item)"><b-icon icon="trash"></b-icon></b-button>
                                    </div>
                                    <div class="text-danger small-text" v-if="item.reply?.title?.length >= 20">
                                        O limite de caracteres (20) foi atingido!
                                    </div>
                                </li>
                            </draggable>
                        </div>
                    </div>
                </div>
            </b-col>
        </b-row>
        <div class="bg-white mt-3 p-3 d-flex justify-content-center" v-if="selectedMenu">
            <b-button class="me-2 managemenubtngreen" @click="saveMenu">Salvar</b-button>
            <b-button class="managemenubtnwhite" @click="goToMenu">Voltar</b-button>
        </div>
        <b-modal id="modal-tags" ref="modal-tags" title="Tags" header-class="py-0 border-0" body-class="p-0" hide-footer>
            <div class="border-top text-secondary p-3" v-for="item in tags" :key="item.id">
                <b style="text-transform: uppercase">{{item.name}}</b> - {{item.description}}
            </div>
        </b-modal>
        <b-modal id="modal-removeMenu" ref="modal-removeMenu" title="Remover Menu" header-class="py-0" hide-footer v-if="itemToDelete">
            <div class="text-secondary">Tem certeza de que seja remover este menu?</div>
            <div class="mt-2">
                <b-button class="managemenubtnred" @click="deleteEl(itemToDelete)">Remover</b-button>
                <b-button class="ms-2 managemenubtnwhite" @click="$bvModal.hide('modal-removeMenu')">Cancelar</b-button>
            </div>
        </b-modal>
        <b-modal id="modalEditSection" ref="modalEditSection" size="lg" class="managemenu-modal" header-class="py-0" :title="`Editar Sessão - ${sectionSelected.title}`" v-if="sectionSelected" hide-footer>
            <b-form-group class="" label="Opções:" label-class="fw-bold text-purple">
                <div class="border border-grey rounded">
                    <div class="px-3 pt-3">
                        <b-button variant="green" class="w-100 text-white" @click="addOption(objAux)">Adicionar</b-button>
                    </div>
                    <draggable tag="ul" :list="objAux.rows" class="list-group" handle=".handle">
                        <li
                        class="d-flex"
                        v-for="(option,index) in objAux.rows"
                        :key="index"
                        >
                            <b-row class="m-0 w-100 border-bottom border-grey p-3 d-flex align-items-end">
                                <b-col class="p-0 pe-3">
                                    <b-form-group label="Título:" label-class="text-purple">
                                        <b-form-input v-model="option.title" :state="option.title.length > 24 ? false : null"></b-form-input>
                                        <b-form-invalid-feedback id="input-live-feedback">
                                            O Título não pode ter mais que 24 caracteres
                                        </b-form-invalid-feedback>
                                    </b-form-group>
                                    
                                </b-col>
                                <b-col class="p-0 pe-3">
                                    <b-form-group label="Descrição:" label-class="text-purple">
                                        <b-form-textarea
                                        v-model="option.description"
                                        rows="1"
                                        max-rows="1"
                                        no-resize
                                        style="height: 0.5em"
                                        ></b-form-textarea>
                                    </b-form-group>
                                </b-col>
                                <b-col class="p-0 w-auto" cols="3">
                                    <b-button class="handle me-2" v-b-tooltip.bottom title="Alterar Ordem">
                                        <b-icon icon="arrows-move"></b-icon>
                                    </b-button>
                                    <b-button variant="red" v-b-tooltip.hover.bottom="{ customClass: 'tooltip-remove' }" title="Remover" v-b-modal.modal-removeMenu @click.prevent="selectItemToDelete(option)">
                                        <b-icon class="text-white" icon="trash"></b-icon>
                                    </b-button>
                                </b-col>
                            </b-row>
                        </li>
                    </draggable>
                    <div class="p-3">
                        <b-button class="managemenubtngreen" @click="saveOptions(objAux)">Avançar</b-button>
                    </div>
                </div>
            </b-form-group>
        </b-modal>
    </div>
</template>

<script>
import { VEmojiPicker } from 'v-emoji-picker';
import draggable from 'vuedraggable'
import api from '../services/apiService.js'
import { getToken } from '../services/userService.js'
import TreeSelectOptions from './treeSelectOptions.vue'
import structuredClone from '@ungap/structured-clone';

export default {
    components: {
        VEmojiPicker,
        draggable,
        TreeSelectOptions,
    },
    props: ['user'],
    mounted: function () {
        if(this.$route.query && !(Object.keys(this.$route.query).length === 0 && this.$route.query.constructor === Object)) {
            this.queryManager(this.$route.query)
        }
        this.onCloseModal()
        this.getMenus()
        this.getOperators()
        this.getDepartments()
        this.$root.$on('selectMenu', value => {
            this.selectMenu(value)
            this.checkAction(value)
            this.collapseOptionSelectVisible = false
        })
    },
    computed: {
        fileAccept() {
            return function (type) {
                switch(type) {
                    case 'image':
                        return 'image/*'
                    case 'audio':
                        return 'audio/*'
                    case 'video':
                        return '.mp4'
                    case 'file':
                        return 'application/msword, application/vnd.ms-excel, application/vnd.ms-powerpoint, text/plain, application/pdf,application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.openxmlformats-officedocument.presentationml.slideshow'
                }
            }
        }
    },
    methods: {
        onCloseModal() {
            this.$root.$on('bv::modal::hide',(bvEvent, modalId) => {
                if(modalId != 'modal-removeMenu') {
                    this.sectionSelected = null
                    this.objAux = {}
                }
            })
        },
        async getMenus() {
            const resp = await api.getMenus(this.user.channelId || this.user.roleId)
            console.log('resp menus',resp)
            if(resp.statusCode == 200) {
                if(resp.menus.length) {
                    const promise = resp.menus.map(async menu => {
                        if(menu.menus.length) {
                            const p = menu.menus.map(async el => {
                                if(typeof el == 'object') {
                                    if(el.action == 'submenu') {
                                        const menuRedirect = resp.menus.find(menu => menu._id == el.redirectId)
                                        if(menuRedirect)
                                            if(menuRedirect?.interactive?.type) 
                                                el.actionType = menuRedirect.interactive.type
                                            else
                                                el.actionType = el.action
                                    } else
                                        el.actionType = el.action
                                    el.topMenuId = menu._id || undefined
                                    return el
                                }
                            })
                            await Promise.all(p)
                        }
                        if(menu.interactive?.action?.sections?.length) {
                            const p = menu.interactive.action.sections.map(async el => {
                                if (el) {
                                    for (const row of el.rows) {
                                        if(row.action == 'submenu') {
                                            const menuRedirect = resp.menus.find(menu => menu._id == row.redirectId)
    
                                            if(menuRedirect) {
                                                if(menuRedirect?.interactive?.type) {
                                                    row.actionType = menuRedirect.interactive.type
                                                } else {
                                                    row.actionType = el.action || row.action
                                                }
                                            }
                                        } else
                                            row.actionType = row.action
                                        row.topMenuId = menu._id || undefined
                                    }
                                    return el
                                }
                            })
                            await Promise.all(p)
                        }
                        if(menu.interactive?.action?.buttons?.length) {
                            const p = menu.interactive.action.buttons.map(async el => {
                                if (el.reply) {
                                    if(el.reply.action == 'submenu') {
                                        let menuRedirect = resp.menus.find(menu => menu._id == el.reply.redirectId)
                                        if(menuRedirect)
                                            if(menuRedirect.interactive?.type) 
                                                el.reply.actionType = menuRedirect.interactive.type
                                            else
                                                el.reply.actionType = el.reply.action
                                    } else
                                        el.reply.actionType = el.reply.action
                                    el.reply.topMenuId = menu._id || undefined
                                }
                                return el
                            })
                            await Promise.all(p)
                        }

                        return menu
                    })
                    await Promise.all(promise)
                    console.log('menus',resp.menus)
                    this.menus = resp.menus
                }
            }
            this.selectMenuOptions = []
            this.setSelectMenuOptions()
        },
        setSelectMenuOptions() {
            let mainMenuIndex
            
            if(this.menuId)
                mainMenuIndex = this.menus.findIndex(el => el._id === this.menuId)
            else
                mainMenuIndex = this.menus.findIndex(el => !el.topMenuId)

            const mainMenu = this.menus[mainMenuIndex]
            if(mainMenu.interactive?.type) {
                if(mainMenu.interactive.type == 'list') {
                    if(mainMenu.interactive?.action?.sections?.length)
                        for (const section of mainMenu.interactive.action.sections) {
                            for (const row of section.rows) {
                                row.preTitle = `Sessão ${section.title}`
                                this.selectMenuOptions.push({
                                    value: row,
                                    text: `Sessão ${section.title} - ${row.title}`,
                                    submenus: this.getOptionsSubmenus(row)
                                })
                            }
                        }
                } else if(mainMenu.interactive.type == 'button')
                    if(mainMenu.interactive?.action?.buttons?.length)
                        for (const button of mainMenu.interactive.action.buttons) {
                            button.reply.preTitle = "Botão"
                            button.reply._id = button._id
                            this.selectMenuOptions.push({
                                value: button.reply,
                                text: `Botão - ${button.reply.title}`,
                                submenus: this.getOptionsSubmenus(button.reply)
                            })  
                        }
            } else
                if(mainMenu.menus?.length)
                    for (const option of mainMenu.menus)
                        this.selectMenuOptions.push({
                            value: option,
                            text: `${option.option} - ${option.textOption}`,
                            submenus: this.getOptionsSubmenus(option)
                        })  
        },
        getOptionsSubmenus(option) {
            const submenus = []
            // console.log('menus', this.menus)
            const mainMenuIndex = this.menus.findIndex(el => !el.topMenuId)
            const s = this.menus.filter((value, index) => mainMenuIndex != index) // submenus
            const submenu = s.find(el => el._id == option.redirectId)
            if(option.actionType == 'submenu') {
                if (submenu) {
                    for (const op of submenu.menus) {
                        this.menus.forEach(menu => {
                            if(menu.menus?.length) {
                                const find = menu.menus.find(el => el._id == op._id)
                                if (find) {
                                    submenus.push({
                                        value: find,
                                        text: `${find.option} - ${find.textOption}`,
                                        submenus: this.getOptionsSubmenus(find)
                                    })
                                }
                            }
                        })
                    }
                }
                // const submenusResp = await api.getMenuRefs(option._id)
                // if(submenusResp.statusCode == 200 && submenusResp.menu.menus?.length) {
                //     for(const op of submenusResp.menu.menus) {
                //         let find
                //         this.menus.forEach(menu => {
                //             if(menu.menus?.length && !find)
                //                 find = menu.menus.find(el => el._id == op._id)
                //         })
                //         submenus.push({
                //             value: find,
                //             text: `${find.option} - ${find.textOption}`,
                //             submenus: await this.getOptionsSubmenus(find)
                //         })
                //     }
                // }
            } else if (option.actionType == 'list') {
                if (submenu) {
                    for (const op of submenu.interactive?.action?.sections) {
                        this.menus.forEach(menu => {
                            if(menu.interactive.action?.sections?.length) {
                                const find = menu.interactive.action.sections.find(el => el._id == op._id)
                                if(find?.rows?.length) {
                                    for (const row of find.rows) {
                                        row.preTitle = `Sessão ${find.title}`
                                        submenus.push({
                                            value: row,
                                            text: `Sessão ${find.title} - ${row.title}`,
                                            submenus: this.getOptionsSubmenus(row)
                                        })
                                    }
                                }
                            }
                        })
                    }
                }
                
                // const submenusResp = await api.getMenuRefs(option._id)
                // if(submenusResp.statusCode == 200 && submenusResp.menu.interactive?.action?.sections?.length) {
                //     for(let op of submenusResp.menu.interactive.action.sections) {
                //         let find
                //         this.menus.forEach(menu => {
                //             if(menu.interactive.action?.sections?.length && !find)
                //                 find = menu.interactive.action.sections.find(el => el._id == op._id)
                //         })
                //         if(find && find.rows?.length) 
                //             for (let row of find.rows) {
                //                 row.preTitle = `Sessão ${find.title}`
                //                 submenus.push({
                //                     value: row,
                //                     text: `Sessão ${find.title} - ${row.title}`,
                //                     submenus: await this.getOptionsSubmenus(row)
                //                 })
                //             }
                //     }
                // }
            } else if (option.actionType == 'button') {
                if (submenu) {
                    for (const op of submenu.interactive?.action?.buttons) {
                        this.menus.forEach(menu => {
                            let find
                            if(menu.menus?.length && !find)
                                find = menu.menus.find(el => el._id == op._id)
                            if(menu.interactive.action?.sections?.length && !find)
                                find = menu.interactive.action.sections.find(el => el._id == op._id)
                            if(menu.interactive.action?.buttons?.length && !find)
                                find = menu.interactive.action.buttons.find(el => el._id == op._id)
                            if(find) {
                                find.reply.preTitle = "Botão"
                                find.reply._id = find._id
                                submenus.push({
                                    value: find.reply,
                                    text: `Botão - ${find.reply.title}`,
                                    submenus: this.getOptionsSubmenus(find.reply)
                                })
                            }
                        })
                    }
                }
            }
            return submenus
        },
        async getOperators() {
            const resp = await api.getOperators(this.user.channelId || this.user.roleId, true)
            if(resp.statusCode!=200) {
                this.operators = []
                return
            }
            this.operators = resp.operators
        },
        async getDepartments() {
            const resp = await api.getDepartments(this.user.channelId || this.user.roleId, true)
            if(resp.statusCode!=200) {
                this.departments = []
                return
            }
            this.departments = resp.departments
        },
        selectMenu(item) {
            this.selectedMenu = item
        },
        selectSection(section) {
            this.sectionSelected = section
            this.objAux = structuredClone(section)
        },
        async checkAction(item) {
            console.log('item',item)
            this.submenu = null
            const mainMenuIndex = this.menus.findIndex(el => !el.topMenuId)
            const submenus = this.menus.filter((value, index) => mainMenuIndex != index) // submenus
            let submenu = submenus.find(el => el.refObjectMenus == item._id)

            if (!submenu) {
                const resp = await api.getMenuRefs(item._id)
                // console.log({resp})
                if (resp.statusCode === 200) {
                    submenu = resp.menu
                }
            }

            if(item.actionType === 'submenu' || item.actionType === 'integration_url') {
                this.$set(item, 'action', item.actionType)

                if (item.actionType === 'integration_url' && !item.integration) {
                    item.integration = {
                        fallbackType: ""
                    }
                }

                // let submenu = submenus.find(el => el.refObjectMenus == item._id)
                if(submenu) {
                    if(!item.redirectId || item.redirectId !== submenu._id)
                        item.redirectId = submenu._id
                    if(!submenu.media.length)
                        submenu.media.push({})
                    if(submenu.menus?.at(0) === "null")
                        submenu.menus = []

                    if(submenu.interactive) {
                        // if (submenu.interactive?.action) {
                        //     submenu.interactive.action.button = ""
                        // }

                        // submenu.interactive.type = null
                        submenu.interactive = null
                    }

                    this.submenu = submenu
                } else {
                    const menu = { 
                        name: `submenu-${item._id}`,
                        topMenuId: item.topMenuId || undefined,
                        refObjectMenus: item._id,
                        channelId: this.user.channelId || this.user.roleId,
                        interactive: null
                    }
                    const respCreate = await api.createMenu(menu)
                    // console.log('resp createMenu',respCreate)
                    if((respCreate.statusCode != 200 && respCreate.statusCode != 201) || !respCreate.menu) {
                        this.$emit('msg', {
                            text: "Ocorreu um erro ao criar menu!",
                            success: false
                        })
                    } else {
                        this.menus.push(respCreate.menu)
                        submenu = this.menus[this.menus.length-1]
                        submenu.media.push({})
                        let find
                        this.menus.forEach(menu => {
                            if(menu.menus?.length && !find)
                                find = menu.menus.find(el => el._id == item._id)
                            if(menu.interactive?.action?.sections?.length && !find)
                                for(let section of menu.interactive.action.sections)
                                    if(section.rows?.length)
                                        find = section.rows.find(el => el._id == item._id)
                            if(menu.interactive?.action?.buttons?.length && !find)
                                find = menu.interactive.action.buttons.find(el => el._id == item._id)
                        })
                        if(find)
                            if(find.reply)
                                find.reply.redirectId = respCreate.menu._id
                            else
                                find.redirectId = respCreate.menu._id
                        this.submenu = submenu
                    }
                }
                this.submenu.type = item.actionType
            } else if(item.actionType == 'list') {
                this.$set(item,'action','submenu')
                if(submenu) {
                    if(!item.redirectId || item.redirectId != submenu._id)
                        item.redirectId = submenu._id
                    if(!submenu.interactive) 
                        submenu.interactive = {
                            type: "list",
                            header: {
                                text: ''
                            },
                            body: {
                                text: ''
                            },
                            footer: {
                                text: ''
                            },
                            action: {
                                button: "Menu",
                                sections: []
                            }
                        }
                    if(!submenu.interactive.action?.button)
                        submenu.interactive.action.button = "menu"
                    submenu.menus = ["null"]
                    submenu.interactive.type = "list"
                    submenu.interactive.media = submenu.media
                    submenu.interactive.imageEnabled = submenu.imageEnabled || false
                    this.submenu = submenu.interactive
                    this.submenu._id = submenu._id
                    if(!this.submenu.body)
                        this.submenu.body = { text: '' }
                    if(!this.submenu.header)
                        this.submenu.header = { type: 'text', text: '' }
                    if(!this.submenu.footer)
                        this.submenu.footer = { text: '' }
                    if(!this.submenu.type)
                        this.submenu.type = 'list'
                    if(!this.submenu.action)
                        this.submenu.action = { sections: [] }
                    if(!this.submenu.action.sections)
                        this.submenu.action.sections = []
                    if(this.submenu.action.buttons)
                        delete this.submenu.action.buttons
                    console.log("submenu after",submenu)
                } else {
                    const menu = { 
                        name: `submenu-${item._id}`,
                        topMenuId: item.topMenuId || undefined,
                        refObjectMenus: item._id,
                        channelId: this.user.channelId || this.user.roleId,
                        interactive: {
                            type: 'list',
                            header: {
                                text: ''
                            },
                            body: {
                                text: ''
                            },
                            footer: {
                                text: ''
                            },
                            action: {
                                sections: []
                            }
                        },
                    }
                    const respCreate = await api.createMenu(menu)
                    // console.log('resp createMenu',resp)
                    if((respCreate.statusCode != 200 && respCreate.statusCode != 201) || !respCreate.menu) {
                        this.$emit('msg', {
                            text: "Ocorreu um erro ao criar menu!",
                            success: false
                        })
                    } else {
                        this.menus.push(respCreate.menu)
                        submenu = this.menus[this.menus.length-1]
                        let find
                        this.menus.forEach(menu => {
                            if(menu.menus?.length && !find)
                                find = menu.menus.find(el => el._id == item._id)
                            if(menu.interactive?.action?.sections?.length && !find)
                                for(let section of menu.interactive.action.sections)
                                    if(section.rows?.length)
                                        find = section.rows.find(el => el._id == item._id)
                            if(menu.interactive?.action?.buttons?.length && !find)
                                find = menu.interactive.action.buttons.find(el => el._id == item._id)
                        })
                        if(find)
                            if(find.reply)
                                find.reply.redirectId = respCreate.menu._id
                            else
                                find.redirectId = respCreate.menu._id
                        submenu.interactive.media = submenu.media
                        submenu.interactive.imageEnabled = submenu.imageEnabled
                        this.submenu = submenu.interactive
                        this.submenu._id = submenu._id
                    }
                }
            } else if(item.actionType == 'button') {
                this.$set(item,'action','submenu')
                if(submenu) {
                    if(!item.redirectId || item.redirectId != submenu._id)
                        item.redirectId = submenu._id
                    if(!submenu.interactive)
                        submenu.interactive = {
                            type: 'button',
                            header: {
                                text: ''
                            },
                            body: {
                                text: ''
                            },
                            footer: {
                                text: ''
                            },
                            action: {
                                buttons: []
                            }
                        }
                    submenu.menus = ["null"]
                    submenu.interactive.type = 'button'
                    this.submenu = submenu.interactive
                    this.submenu._id = submenu._id
                    if(!this.submenu.body)
                        this.submenu.body = { text: '' }
                    if(!this.submenu.header)
                        this.submenu.header = { type: 'text', text: '' }
                    if(!this.submenu.footer)
                        this.submenu.footer = { text: '' }
                    if(!this.submenu.type)
                        this.submenu.type = 'list'
                    if(!this.submenu.action)
                        this.submenu.action = { buttons: [] }
                    if(!this.submenu.action.buttons)
                        this.submenu.action.buttons = []
                    if(this.submenu.action.sections)
                        delete this.submenu.action.sections
                } else {
                    const menu = { 
                        name: `submenu-${item._id}`,
                        topMenuId: item.topMenuId || undefined,
                        refObjectMenus: item._id,
                        channelId: this.user.channelId || this.user.roleId,
                        interactive: {
                            type: 'button',
                            header: {
                                text: ''
                            },
                            body: {
                                text: ''
                            },
                            footer: {
                                text: ''
                            },
                            action: {
                                buttons: []
                            }
                        },
                    }
                    const respCreate = await api.createMenu(menu)
                    // console.log('resp createMenu',resp)
                    if((respCreate.statusCode != 200 && respCreate.statusCode != 201) || !respCreate.menu) {
                        this.$emit('msg', {
                            text: "Ocorreu um erro ao criar menu!",
                            success: false
                        })
                    } else {
                        this.menus.push(respCreate.menu)
                        submenu = this.menus[this.menus.length-1]
                        let find
                        this.menus.forEach(menu => {
                            if(menu.menus?.length && !find)
                                find = menu.menus.find(el => el._id == item._id)
                            if(menu.interactive?.action?.sections?.length && !find)
                                for(let section of menu.interactive.action.sections)
                                    if(section.rows?.length)
                                        find = section.rows.find(el => el._id == item._id)
                            if(menu.interactive?.action?.buttons?.length && !find)
                                find = menu.interactive.action.buttons.find(el => el._id == item._id)
                        })

                        if(find)
                            if(find.reply)
                                find.reply.redirectId = respCreate.menu._id
                            else
                                find.redirectId = respCreate.menu._id
                        this.submenu = submenu.interactive
                        this.submenu._id = submenu._id
                    }
                }
            } else
                this.$set(item,'action',item.actionType)
        },
        async saveMenu() {
            console.log('saveMenus',this.menus)
            const promise = this.menus.map(async menu => {
                if(menu.interactive?.type == 'list' && (this.user.channel?.apiType == 'cloud' || this.user.channel?.apiType == 'gupshup')) {
                    if(!menu.interactive.action?.button) {
                        console.log('menuId',menu._id)
                        return
                        // return this.$emit('msg', {
                        //     text: 'O campo "Texto do Botão" precisa ser preenchido!',
                        //     success: false
                        // })
                    }
                    menu.menus = ["null"]
                    if(!menu.interactive.header.type)
                        menu.interactive.header.type = 'text'
                    if(this.submenu) {
                        menu.imageEnabled = this.submenu.imageEnabled
                        menu.media = this.submenu.media
                    }
                    if(menu.interactive.action.buttons) delete menu.interactive.action.buttons
                } else if (menu.interactive?.type == 'button' && (this.user.channel?.apiType == 'cloud' || this.user.channel?.apiType == 'gupshup')) {
                    if(menu.interactive.action.sections) delete menu.interactive.action.sections
                    if(menu.interactive.action.button) delete menu.interactive.action.button
                    menu.menus = ["null"]
                    if(!menu.interactive.header.type)
                        menu.interactive.header.type = 'text'
                } else {
                    if (menu.interactive?.action) {
                        menu.interactive.action.button = ""
                    }
                    if(menu.text && !menu.contentOrder.find(el => el == 'text'))
                        menu.contentOrder.push('text')
                    if(menu.interactive?.type)
                        menu.interactive.type = ""
                    if(!menu.menus?.length)
                        menu.menus = ["null"]
                }

                if (!menu.action) {
                    const topMenu = this.menus.find(el => el._id === menu.topMenuId)

                    if (topMenu?.menus || topMenu?.interactive?.action?.sections || topMenu?.interactive?.action?.buttons) {
                        let index
                        if (topMenu?.interactive?.action?.sections) {
                            for (const section of topMenu.interactive.action.sections) {
                                index = section.rows.findIndex(el => el.redirectId === menu._id)
                            }
                        } else if (topMenu?.interactive?.action?.buttons) {
                            index = topMenu.interactive.action.buttons.findIndex(el => el.reply?.redirectId === menu._id)
                        } else {
                            index = topMenu.menus.findIndex(el => el.redirectId === menu._id)
                        }

                        if (index > -1) {
                            let option 
                            if (topMenu?.interactive?.action?.sections) {
                                option = topMenu.interactive.action.sections[index].rows[index]
                            } else if (topMenu?.interactive?.action?.buttons) {
                                option = topMenu.interactive.action.buttons[index].reply                             
                            } else {
                                option = topMenu.menus[index]
                            }

                            if (option.action === 'integration_url') {
                                menu.action = option.action
                                menu.integration = option.integration
                            }
                        }
                    }
                }

                if(menu.contentOrder?.length && menu.contentOrder.findIndex(el => el != 'text')) {
                    const textIndex = menu.contentOrder.findIndex(el => el == 'text')
                    if(textIndex >= 0) {
                        menu.contentOrder.splice(textIndex,1)
                    }
                }

                if(menu.isLinear)
                    menu.isLinear = false
                if(!menu.topMenuId) 
                    menu.topMenuId = undefined
                if(!menu.refObjectMenus)
                    menu.refObjectMenus = undefined

                await api.updateMenu({ menus: [ menu ] })
            })
            await Promise.all(promise)
            this.getMenus()
            this.selectedMenu = null
            this.submenu = null
            this.$emit('msg', {
                text: "Menu salvo com sucesso!",
                success: true
            })
        },
        async savePresentation() {
            const resp = await api.updatePresentation(
                { textPresentation: this.submenu.body.text, textButton: this.submenu.action.button, header: this.submenu.header, footer: this.submenu.footer },
                this.submenu._id
            )
            return resp.statusCode
        },
        async saveSections() {
            const resp = await api.updateSections(this.submenu.action.sections,this.submenu._id)
            // console.log('sectionsResp',resp)
            return resp.statusCode
        },
        setContentOrder(content) {
            if(content == 'text') {
                if(this.submenu.contentOrder?.length && !this.submenu.contentOrder.findIndex(el => el != 'text')) {
                    const textIndex = this.submenu.contentOrder.findIndex(el => el == 'text')
                    if(textIndex >= 0) {
                        this.submenu.contentOrder.splice(textIndex,1)
                    } else {
                        return
                    }
                }
            } else if(!this.submenu.contentOrder.find(el => el == 'text')) this.setContentOrder('text')

            if(!this.submenu.contentOrder.find(el => el == content)) {
                this.submenu.contentOrder.push(content)
            }
        },
        checkMove() {
            const menu = this.submenu || this.menu
            for (let i = 0; i < menu.menus.length; i++) {
                menu.menus[i].option = `${i + 1}`
            }
        },
        verifyOption(array) {
            return Math.max(...array.map(({ option }) => parseInt(option))) + 1;
        },
        goToMenu() {
            if(this.user.channel?.apiType == 'cloud' || this.user.channel?.apiType == 'gupshup')
                this.$router.replace({path:`/manageChatbot`})
            else
                this.$router.replace({path:`/managemenu`})
            // let mainMenuIndex = this.menus.findIndex(el => !el.topMenuId)
            // let mainMenu = this.menus[mainMenuIndex]
            // if(mainMenu.interactive?.type) {
            //     if(mainMenu.interactive.type == 'list')
            //         this.$router.replace({path:`/manageList`})
            //     else if(mainMenu.interactive.type == 'button')
            //         this.$router.replace({path:`/manageButton`})
            // }
        },
        selectEmojiIcon(emoji) {
            this.itemSelected.icone = emoji.data
            this.toggleDialogEmojiIcon()
            this.itemSelected = null
        },
        toggleDialogEmojiSubmenuTextarea() {
            this.showDialogSubmenuTextarea = !this.showDialogSubmenuTextarea;
        },
        selectEmojiSubmenuTextarea(emoji) {
            this.submenu.text += emoji.data
            this.toggleDialogEmojiSubmenuTextarea()
        },
        toggleDialogEmojiTextarea() {
            this.showDialogTextarea = !this.showDialogTextarea;
        },
        toggleDialogEmojiHeader() {
            this.showDialogHeader = !this.showDialogHeader;
        },
        toggleDialogEmojiFooter() {
            this.showDialogFooter = !this.showDialogFooter;
        },
        toggleDialogEmojiIcon() {
            this.showDialogIcon = !this.showDialogIcon;
        },
        selectEmojiTextarea(emoji) {
            if(!this.submenu.body.text)
                this.submenu.body.text = ""
            this.submenu.body.text += emoji.data
            this.toggleDialogEmojiTextarea()
        },
        selectEmojiHeader(emoji) {
            if(!this.submenu.header.text)
                this.submenu.header.text = ""
            this.submenu.header.text += emoji.data
            this.toggleDialogEmojiHeader()
        },
        selectEmojiFooter(emoji) {
            if(!this.submenu.footer.text)
                this.submenu.footer.text = ""
            this.submenu.footer.text += emoji.data
            this.toggleDialogEmojiFooter()
        },
        changeMedia(e) {
            if(!e) {
                this.submenu.media = [{ url:'' }]
                const mediaIndex = this.submenu.contentOrder.findIndex(el => el != 'text')
                this.submenu.contentOrder.splice(mediaIndex,1)
            }
        },
        async setMenuMedia(e) {
            if(e) {
                console.log('file',e)
                if (e.size > 10000000) {
                    this.$emit('msg', {
                        text: "O tamanho máximo permitido para envios é de 10 MB",
                        danger: true
                    })
                }
                if(e.type=='video/x-matroska' || !e.type) {
                    this.$emit('msg', {
                        text: "Extensão não suportada",
                        danger: true
                    })
                }
                const formData = new FormData();
                e.filename = e.name
                formData.append('file',e)
                let typefile = e['type'].split('/')[0]
                const headers = { 'Content-Type': 'multipart/form-data', 'type': 'file', 'typefile': typefile, 'clientNumber': this.user.channel.channelNumber }
                const uploadResp = await api.upload(formData,headers)
                // console.log('uploadResp',uploadResp)
                const url = uploadResp.file.uploadDir
                if(!this.submenu.media?.length) 
                    this.submenu.media = [{ url: "" }]
                if(typefile == 'application' || typefile == 'text')
                    typefile = 'file'
                const obj = {
                    url,
                    type: typefile,
                    filename: e.filename,
                    ext: e.name.split('').reverse().join('').split('.')[0].split('').reverse().join('')
                }
                this.$set(this.submenu.media,0,obj)
                this.setContentOrder(typefile)
            }
        },
        selectItem(item,topMenu,$event=null) {
            this.itemSelected = item
            if(topMenu?._id) {
                this.itemSelected.topId = topMenu._id
            }
            if($event) {
                this.toggleDialogEmojiIcon()
            }
        },
        selectItemToDelete(item) {
            this.itemToDelete = item;
        },
        deleteEl(element) {
            const menu = this.submenu
            console.log(this.selectedMenu)
            if(this.selectedMenu.actionType == 'list') {
                if(this.sectionSelected)
                    this.objAux.rows = this.objAux.rows.filter(function(el) { return el != element })
                else
                    menu.action.sections = menu.action.sections.filter(function(el) { return el != element })
            }
            else if(this.selectedMenu.actionType == 'button')
                menu.action.buttons = menu.action.buttons.filter(function(el) { return el != element })
            else {
                const order = element.option
                menu.menus = menu.menus.filter(function(el) { return el != element });
                menu.menus.forEach(el=>{
                    if(el.option>order) {
                        el.option--   
                        el.option=el.option.toString()
                    }
                    return el
                })
            }
            if(this.$refs['modal-removeMenu'])
                this.$refs['modal-removeMenu'].hide()
            
            this.itemToDelete = undefined
        },
        async getMenuOptions(menuId) {
            const resp = await api.getMenuOptions(menuId)
            if(resp.statusCode != 200) {
                return this.$emit('msg', {
                    text: "Ocorreu um erro ao carregar menus!",
                    sucess: false
                })
            }

            const menu = this.submenu
            if(menuId == menu._id) {
                menu.menus = resp.options
                this.$set(menu,'menus',resp.options)
            }
        },
        async addMenu() {
            const menu = this.submenu
            let option = 1
            if(menu?.menus?.length)
                option = this.verifyOption(menu.menus)
            else {
                menu.menus = []
            }
            const op = {
                option: option.toString(),
                icone: '',
                textOption: 'Novo Menu',
            }
            this.submenu.menus.push(op)
            this.submenu.channelId = "1"
            this.submenu.channelId = this.user.channelId || this.user.roleId
        },
        addSection() {
            // console.log('this submenu',this.submenu)
            if(this.submenu.action?.sections?.length >= 10) {
                return this.$emit('msg', {
                    text:'O número máximo (10) de Sessões foi atingido!',
                    success: false,
                }) 
            }
            this.submenu.action.sections.push({
                rows: [],
                title: ''
            })
            this.submenu.type = 'lista'
            this.submenu.type = 'list'
        },
        addButton() {
            if(this.submenu.action.buttons.length >= 3) {
                return this.$emit('msg', {
                    text:'O número máximo (3) de Botões foi atingido!',
                    success: false,
                }) 
            }
            this.submenu.action.buttons.push({
                reply: {
                    title: ""
                }
            })
            this.submenu.type = 'buttona'
            this.submenu.type = 'button'
        },
        addOption(section) { // add option to a row
            section.rows.push({ title: '', description: '' })
        },
        saveOptions(section) {
            if(section.rows.length) {
                let error
                section.rows.map(el=>{
                    if(el.title.length > 24) {
                        el.invalid = true
                        this.$emit('msg', {
                            text:'O Título não pode ter mais que 24 caracteres',
                            success: false,
                        })
                        error = true
                        return el
                    }
                })
                if(error) return
            }
            this.sectionSelected.rows = section.rows
            this.$refs['modalEditSection'].hide()
        },
        async selectMenuImage(e) {
            if(e) {
                if (e.size > 10000000) {
                    this.$emit('msg', {
                        text: "O tamanho máximo permitido para envios é de 10 MB",
                        danger: true
                    })
                }
                const formData = new FormData();
                e.filename = e.name
                formData.append('file',e)
                let typefile = e['type'].split('/')[0]
                const token = getToken()
                const headers = {'Content-Type': 'multipart/form-data', 'type': 'file', 'typefile': typefile, 'authorization': token, 'clientNumber': this.user.channelId }
                const uploadResp = await api.upload(formData,headers)
                const url = uploadResp.file.uploadDir
                if(!this.submenu.media?.length)
                    this.submenu.media.push({ url: '' })
                const obj = {
                    url,
                    type: typefile,
                    filename: e.filename,
                    ext: e.name.split('').reverse().join('').split('.')[0].split('').reverse().join('')
                }
                this.$set(this.submenu.media,0,obj)
                this.submenu.action.buttons.push({
                    reply: {
                        title: ""
                    }
                })
                this.submenu.action.buttons.pop()
            }
        },
        async selectHeaderImage(e) {
            if(e) {
                if (e.size > 10000000) {
                    this.$emit('msg', {
                        text: "O tamanho máximo permitido para envios é de 10 MB",
                        danger: true
                    })
                }
                const formData = new FormData();
                e.filename = e.name
                formData.append('file',e)
                let typefile = e['type'].split('/')[0]
                const token = getToken()
                const headers = {'Content-Type': 'multipart/form-data', 'type': 'file', 'typefile': typefile, 'authorization': token}
                const uploadResp = await api.upload(formData,headers)
                const url = uploadResp.file.uploadDir
                this.submenu.header.type = 'image'
                this.$set(this.submenu.header,'link',url)
                this.submenu.action.buttons.push({
                    reply: {
                        title: ""
                    }
                })
                this.submenu.action.buttons.pop()
            }
        },
        toggleImageEnabledList(e) {
            this.submenu.imageEnabled = e
            this.submenu.action.buttons.push({
                reply: {
                    title: ""
                }
            })
            this.submenu.action.buttons.pop()
        },
        formsEnabledChange(value) {
            if(value && !this.selectedMenu.forms) {
                this.$set(this.selectedMenu,'forms',[{
                    text: '',
                }])
            }
        },
        addForm() {
            if(this.selectedMenu.forms.length >= 5) {
                return this.$emit('msg', {
                    text:'O número máximo (5) de formulários foi atingido!',
                    success: false,
                })
            }
            this.selectedMenu.forms.push({
                text: '',
                field: ''
            })
        },
        removeForm(index) {
            this.selectedMenu.forms.splice(index,1)
        },
        queryManager(queryObj) {
            if(queryObj.id)
                this.menuId = queryObj.id
        },
        refreshSelectedMenu() {
            this.$set(this.selectedMenu, "refresh", 1)
            delete this.selectedMenu.refresh
        }
    },
    data() {
        return {
            itemSelected: null,
            showDialogSubmenuTextarea: false,
            menus: [],
            menuId: null,
            submenu: null,
            selectedMenu: null,
            sectionSelected: null,
            objAux: {},
            selectMenuOptions: [],
            departments: [],
            operators: [],
            textEnabled: true,
            showDialogTextarea: false,
            showDialogHeader: false,
            showDialogFooter: false,
            showDialogIcon: false,
            itemToDelete: null,
            collapseOptionSelectVisible: false,
            i18n: {
                search: 'Pesquisar...',
                categories: {
                    Activity: "Atividades",
                    Flags: "Bandeiras",
                    Foods: "Comida",
                    Frequently: "Frequentes",
                    Objects: "Objetos",
                    Nature: "Natureza",
                    Peoples: "Pessoas",
                    Symbols: "Símbolos",
                    Places: "Locais"
                }
            },
            tags: [
                {
                    name: '%NOME_CLIENTE%',
                    description: 'Identifica nome do contato',
                },
                {
                    name: '%DTN%',
                    description: 'Identifica mensagem de saudação de acordo com o horário de envio',
                },
                {
                    name: '#',
                    description: 'Voltar ao Menu Anterior',
                },
                {
                    name: '*',
                    description: 'Finalizar Atendimento',
                },
            ],
        }
    }
}
</script>

<style>
    .emoji-picker #Categories {
        order: 5;
    }
    .emoji-picker #InputSearch {
        order: 4;
    }
    .emoji-picker .border{
        border: none !important;
    }
    .emoji-picker .emoji{
        display: flex !important;
        justify-content: center !important;
    }
    .emoji-picker .container-emoji{
        overflow: auto !important;
    }
    .formFile .custom-file-input{
        display: none !important;
    }
    .formFile .custom-file-label{
        cursor: pointer;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
    }
</style>

<style scoped>
    .cardnumber{
        margin-right: 15px;
        background-color: hsl(94deg 44% 65%);
        color: #fff;
        display:flex;
        align-items: center;
        font-size:25px;
        font-weight: 500;
        /* vertical-align: middle; */
    }
    .cardnumber:before,
    .cardnumber:after {
        content:'\200B';
        display:flex;
        line-height:0px;
        padding-top:50%;
        padding-bottom:50%;
    }
    .cardnumber:before {
        padding-left:12px;
    }
    .cardnumber:after {
        padding-right:12px;
    }
    .managemenubtngreen:not(:last-child) {
        margin-right: 10px;
    }
    .managemenubtngreen{
        background-color: hsl(142deg 38% 59%);
        border-color: hsl(142deg 38% 59%);
        padding: 5px 20px;
    }
    .managemenubtnpink{
        background-color: hsl(339deg 81% 69%);
        border-color: hsl(339deg 81% 69%);
        padding: 5px 20px;
    }
    .managemenubtnred{
        background-color: hsl(360deg 83% 69%);
        border-color: hsl(360deg 83% 69%);
        padding: 5px 20px;
    }
    .managemenubtnwhite{
        background-color: #fff;
        color: #777;
        border-color: #eee;
    }
    .managemenubtnwhite:hover{
        background-color: #777;
        color: #fff 
    }
    select, input, textarea{
        border-color: #eee;
        color: #555;
    }
    .form-control, .custom-select{
        padding: 0.375rem 0.75rem;
    }
    .toggle-emoji{
        position: absolute;
        bottom: 0;
        right: 8px;
        background-color: transparent;
        border: none;
        color: #aaa;
        box-shadow: none !important;
        cursor: default;
    }
    .emoji-picker{
        position: absolute;
        bottom: 40px;
        right: 0;
        width: 250px;
        z-index: 1;
    }
    .textareaemoji-container, .iconemojipicker-container{
        position: relative;
    }
    .cardmainmenuitem{
        display: flex;
        align-items: center;
        text-align: center;
        height: 50px;
        border-radius: 2px;
    }
    .cardmainmenuitem:not(:last-child) {
        margin-right: 18px;
    }
    .cardmainmenunumber{
        width: 60px;
    }
    .cardmainmenuicon{
        background-color: hsl(142deg 38% 59%);
        border-color: hsl(142deg 38% 59%);
        width: 4em;
    }
    .cardmainmenuicon:hover{
        background-color: hsl(142deg 30% 50%);
        border-color: hsl(142deg 30% 50%);
    }
    .cardmainmenuname{
        margin: 0 !important;
        text-align: left;
    }
    .input-group .input-group-append{
        height: 100%;
    }
    .cardmainmenueyebtn{
        background-color: hsl(339deg 81% 69%);
        border-color: hsl(339deg 81% 69%);
        font-size: 1.2em;
    }
    .cardmainmenueditbtn{
        background-color: hsl(93deg 44% 65%);
        border-color: hsl(93deg 44% 65%);
        font-size: 1.2em;
    }
    .cardmainmenuremovebtn{
        background-color: hsl(360deg 83% 69%);
        border-color: hsl(360deg 83% 69%);
        font-size: 1.2em;
    }
    .selectMenu:hover{
        background-color: #0984e3;
        color: #fff;
    }
    .selectMenu:first-child{
        border-top-left-radius: .25em;
        border-top-right-radius: .25em;
    }
    .selectMenu:last-child{
        border-bottom-left-radius: .25em;
        border-bottom-right-radius: .25em;
    }
    .addimage .custom-file-input{
        display: none;
    }
    .addimage .custom-file-label{
        display: block;
        cursor: pointer;
    }
    .cardpresentationimg{
        display: flex;
        flex-direction: column;
        align-items: center;
        align-self: center;
        color: #fff;
        background-color: hsl(249deg 9% 28%);
        cursor: pointer;
        width: 100%;
    }
    .toggle-emoji{
        position: absolute;
        bottom: 0.3em;
        right: 1em;
        background-color: transparent;
        border: none;
        color: #aaa;
        box-shadow: none !important;
        cursor: default;
    }
    .cardpresentationimg{
        display: flex;
        flex-direction: column;
        align-items: center;
        align-self: center;
        color: #fff;
        background-color: hsl(249deg 9% 28%);
        padding: 79px;
        cursor: pointer;
        width: 100%;
    }
</style>