<template>
    <div>
        <div class="hstack gap-3 bg-white rounded-top border-bottom-grey p-3 justify-content-between">
            <span class="hstack gap-3">
                <span class="bg-green text-white rounded-circle px-3 py-2 fs-5 fw-bold">
                    {{ currentStage + 1 }}
                </span>
                <span class="fw-semibold fs-5 text-purple" v-if="currentStage === 0">
                    Selecione Nome e Template
                </span>
                <span class="fw-semibold fs-5 text-purple" v-if="currentStage === 1">
                    Selecione Ação
                </span>
                <span class="fw-semibold fs-5 text-purple" v-if="currentStage === 2">
                    Selecione Método de Envio
                </span>
                <span class="fw-semibold fs-5 text-purple" v-if="currentStage === 3">
                    Selecione o Público
                </span>
            </span>
            <b-button variant="success" @click="goBack">
                Voltar
            </b-button>
        </div>
        <b-row class="m-0">
            <b-col class="p-0" cols="7">
                <div class="bg-white p-3 h-100">
                    <b-form class="vstack gap-3" @submit.prevent="advance">
                        <div class="vstack gap-3 text-purple" v-if="currentStage === 0">
                            <b-form-group label="Nome:" label-class="fw-semibold">
                                <b-form-input class="border-grey" maxlength="60" v-model="campaign.name" placeholder="Nome" required />
                                <div class="text-danger small-text mt-1" v-if="campaign.name?.length >= 60">
                                    O limite de caracteres (60) foi atingido!
                                </div>
                            </b-form-group>
                            <b-form-group label="Template:" label-class="fw-semibold">
                                <v-select class="customSelect" placeholder="Template" label="elementName" :reduce="template => template.id" :options="templates" :clearable="false" :loading="loadingTemplates" v-model="campaign.templateId" @input="pickedTemplate" required />
                            </b-form-group>
                        </div>
                        <div class="vstack gap-3 text-purple" v-if="currentStage === 1">
                            <b-form-group label="Habilitar Chatbot:" label-class="fw-semibold" v-if="!this.user.channelConfig?.operationalChannelEnabled">
                                <b-form-radio-group class="hstack gap-3" v-model="campaign.automation.enable" required>
                                    <b-form-radio class="hstack gap-1" :value="false">
                                        Sim
                                    </b-form-radio>
                                    <b-form-radio class="hstack gap-1" :value="true">
                                        Não
                                    </b-form-radio>
                                </b-form-radio-group>
                            </b-form-group>
                            <fieldset class="border-grey2 rounded p-2" v-if="this.user.channelConfig?.operationalChannelEnabled || campaign.automation.enable">
                                <legend  class="float-none w-auto fs-6 fw-semibold">Automação</legend>
                                <div class="vstack gap-2 p-2 pt-0">
                                    <b-form-group label-class="fw-semibold" label="Direcionar para:">
                                        <b-form-select class="text-secondary w-100 rounded p-2 border-grey" required v-model="campaign.automation.action">
                                            <b-form-select-option value="department">Departamento</b-form-select-option>
                                            <b-form-select-option value="operator">Operador</b-form-select-option>
                                        </b-form-select>
                                    </b-form-group>
                                    <b-form-group label-class="fw-semibold" :label="`Selecione o ${ campaign.automation.action == 'department' ? 'Departamento' : 'Operador' }:`" v-if="campaign.automation.action">
                                        <b-form-select class="text-secondary w-100 rounded p-2 border-grey" required v-model="campaign.automation.value" v-if="campaign.automation.action == 'department'">
                                            <b-form-select-option :value="dep._id" v-for="dep in departments" :key="dep._id">{{ dep.name }}</b-form-select-option>
                                        </b-form-select>
                                        <b-form-select class="text-secondary w-100 rounded p-2 border-grey" required v-model="campaign.automation.value" v-else-if="campaign.automation.action == 'operator'">
                                            <b-form-select-option :value="op._id" v-for="op in operators" :key="op._id">{{ op.name }}</b-form-select-option>
                                        </b-form-select>
                                    </b-form-group>
                                </div>
                            </fieldset>
                        </div>
                        <div class="vstack gap-3 text-purple" v-if="currentStage === 2">
                            <b-form-group label="Canal de Envio:" label-class="fw-semibold" v-if="channels.length > 1">
                                <v-select class="customSelect" label="channelNumber" :reduce="channel => channel._id" :options="channels" :clearable="false" v-model="campaign.channelId" required />
                            </b-form-group>
                            <b-form-group label="Quando deseja enviar a campanha:" label-class="fw-semibold">
                                <b-form-radio-group class="hstack gap-3" v-model="campaign.sendingMessagesType" required>
                                    <b-form-radio class="hstack gap-1" value="0">
                                        Envio Imediato
                                    </b-form-radio>
                                    <b-form-radio class="hstack gap-1" value="1">
                                        Agendamento
                                    </b-form-radio>
                                </b-form-radio-group>
                            </b-form-group>
                            <b-form-group label="Data e hora de Envio:" label-class="fw-semibold" v-if="campaign.sendingMessagesType == 1">
                                <b-form-input type="datetime-local" v-model="campaign.sendingMessages.schedulingDate" required />
                            </b-form-group>
                        </div>
                        <div class="vstack gap-3 text-purple" v-if="currentStage === 3">
                            <b-form-group label="Público:" label-class="fw-semibold">
                                <b-form-radio-group class="hstack gap-3" v-model="campaign.contactsType" @input="campaign.contacts = []; campaign.search = null" required>
                                    <b-form-radio class="hstack gap-1" value="0">
                                        Todos
                                    </b-form-radio>
                                    <b-form-radio class="hstack gap-1" value="1">
                                        Seleção de Contatos
                                    </b-form-radio>
                                    <b-form-radio class="hstack gap-1" value="2">
                                        Gênero
                                    </b-form-radio>
                                    <b-form-radio class="hstack gap-1" value="3">
                                        Etiquetas
                                    </b-form-radio>
                                    <b-form-radio class="hstack gap-1" value="4">
                                        Planilha
                                    </b-form-radio>
                                    <b-form-radio class="hstack gap-1" value="5" v-if="user.channelConfig?.enableCustomerPortfolio">
                                        Carteira
                                    </b-form-radio>
                                </b-form-radio-group>
                            </b-form-group>
                            <b-form-group label="Busque os contatos:" label-class="fw-semibold" v-if="campaign.contactsType == 1">
                                <v-select class="customSelect" placeholder="Contatos"
                                    :reduce="contact => contact" :options="[...contacts, ...pickedContacts.filter(contact => !contacts.includes(contact))]"
                                    multiple
                                    @search="(search, loading) => { 
                                        loading(true)
                                        getContacts(campaign.contactsType, search).then(() => loading(false))
                                    }"
                                    :filter="hidePickedContacts"
                                    v-model="pickedContacts" 
                                    required
                                />
                            </b-form-group>
                            <b-form-group label="Selecione o Gênero:" label-class="fw-semibold" v-if="campaign.contactsType == 2">
                                <b-form-radio-group class="hstack gap-3" v-model="campaign.search" required>
                                    <b-form-radio class="hstack gap-1" value="M">
                                        Masculino
                                    </b-form-radio>
                                    <b-form-radio class="hstack gap-1" value="F">
                                        Feminino
                                    </b-form-radio>
                                </b-form-radio-group>
                            </b-form-group>
                            <b-form-group label="Selecione a Etiqueta:" label-class="fw-semibold" v-if="campaign.contactsType == 3">
                                <v-select class="customSelect" placeholder="Etiquetas"
                                    :loading="loadingTags"
                                    :clearable="false"
                                    label="name"
                                    :reduce="tag => tag._id"
                                    :options="tags"
                                    v-model="campaign.search" 
                                    required
                                />
                            </b-form-group>
                            <b-form-group label="Importe a planilha:" label-class="fw-semibold" v-if="campaign.contactsType == 4">
                                <div class="vstack align-items-center gap-3 border-grey2 rounded p-3">
                                    <div>
                                        <b-form-file id="contactsSheetUpload" style="display:none" accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" @input="selectContactSheet" :disabled="importingContactsSheet" />
                                        <label for="contactsSheetUpload">
                                            <div class="bg-grey border-grey2 rounded py-2 px-4 darkenOnHover" role="button">Anexar Arquivo</div>
                                            <div class="smaller-text text-center">
                                                Tamanho máximo de 10MB
                                            </div>
                                        </label>
                                    </div>
                                    <div class="vstack align-items-center">
                                        <div>
                                            Siga o exemplo abaixo:
                                        </div>
                                        <a class="hstack align-self-center gap-1 w-fit" href="/exemplo-importacao.xlsx">
                                            <b-icon icon="file-earmark-spreadsheet-fill" font-scale="1.25" />
                                            Exemplo
                                        </a>
                                        <div class="text-secondary small-text">
                                            Os campos "Nome" e "Telefone" são obrigatórios
                                        </div>
                                        <div class="text-secondary small-text">
                                            Recomendado o envio de arquivos no formato .xls
                                        </div>
                                    </div>
                                    <div class="p-2 text-purple" v-if="importingContactsSheet">
                                        <b-spinner />
                                    </div>
                                    <div class="text-green small-text" v-else-if="importContactsSuccess">
                                        Importado com sucesso!
                                    </div>
                                    <div class="text-red small-text" v-else-if="errorMessage">
                                        {{ errorMessage }}
                                    </div>
                                    <div class="text-red small-text" v-else-if="hasImported">
                                        Nenhum contato encontrado!
                                    </div>
                                </div>
                            </b-form-group>
                            <b-form-group label="Selecione o operador:" label-class="fw-semibold" v-if="campaign.contactsType == 5">
                                <b-form-select class="text-secondary w-100 rounded p-2 border-grey" v-model="campaign.search" required>
                                    <b-form-select-option :value="op._id" v-for="op in operators" :key="op._id">{{ op.name }}</b-form-select-option>
                                </b-form-select>
                            </b-form-group>
                        </div>
                        <div class="hstack gap-2">
                            <b-button type="submit" class="text-light w-fit px-3" variant="green" :disabled="currentStage === 3 && campaign.contactsType == 4 && (this.importingContactsSheet || !this.importContactsSuccess)">
                                {{ currentStage === lastStage ? "Concluir" : "Avançar" }}
                            </b-button>
                            <!-- <b-button class="border-grey text-dark bg-white w-fit px-3" @click="goBack()">
                                Cancelar
                            </b-button> -->
                        </div>
                    </b-form>
                </div>
            </b-col>
            <b-col class="d-flex flex-column p-3 bg-grey">
                    <!-- <div class="fw-semibold text-center">Preview</div> -->
                    <div class="d-flex flex-column border border-3 border-white rounded h-100 shadow-sm">
                        <div class="bg-green2 rounded-top" style="height: 3em;"></div>
                        <Whatsapp id="preview" class="h-100" height="100%" :mediaURL="mediaURL" 
                            :messages="messages" :loading="loadingMessages"
                        />
                    </div>
                </b-col>
        </b-row>
    </div>
</template>

<script>
import api from '../services/apiService.js'
import Whatsapp from './whatsapp.vue'
import * as XLSX from 'xlsx';

export default {
    components: {
        Whatsapp
    },
    props: [
        'user', 'mediaURL'
    ],
    data() {
        return {
            currentStage: 0,
            lastStage: 3,
            campaign: {
                automation: { enable: true },
                sendingMessages: {
                    immediate: false
                },
                search: ""
            },
            loadingTemplates: true,
            loadingMessages: false,
            loadingTags: true,
            importingContactsSheet: false,
            template: null,
            templates: [],
            channels: [],
            messages: [],
            departments: [],
            operators: [],
            contacts: [],
            pickedContacts: [],
            tags: [],
            importContactsSuccess: false,
            hasImported: false,
            errorMessage: null
        }
    },
    mounted: function() {
        this.init()
    },
    watch: {
        "template": {
            handler(newTemplate) {
                if (!newTemplate) return this.messages = []
                this.messages = (newTemplate.header || newTemplate.content || newTemplate.footer)
                    ? [{ 
                        type: 'template', 
                        body: {
                            ...newTemplate, 
                            components: [
                                {type: 'BODY', text: newTemplate.content},
                                {type: 'HEADER', format: 'TEXT', text: newTemplate.header},
                                {type: 'FOOTER', format: 'TEXT', text: newTemplate.footer},
                                { type: 'BUTTONS', buttons: newTemplate.buttons }
                            ] 
                        }, fromMe: true }] 
                    : []
            }
        }
    },
    methods: {
        async init() {
            if (this.user?.channel) {
                await this.getChannels()
                this.getTemplates()
            } else {
                setTimeout(async () => {
                    await this.getChannels()
                    this.getTemplates()
                }, 2000)
            }
            this.getDepartments()
            this.getOperators()
            this.getTags()
        },
        goToCampaigns() {
            this.$router.replace('/manageCampaigns')
        },
        goBack() {
            if (this.currentStage > 0)
                this.currentStage--
            else
                this.goToCampaigns()
        },
        advance() {
            if (this.currentStage < this.lastStage) {
                if (!this.campaign.templateId)
                    return this.$emit("msg", {
                        text: "Template não selecionado",
                        success: false
                    })
                this.currentStage++
            } else {
                this.createCampaign(true)
            }
        },
        async createCampaign(send = false) {
            if (!this.loading) {
                this.loading = true

                const channelId = this.user.channelId || this.user.roleId

                switch (this.campaign.contactsType) {
                    case "0":
                    case "2":
                    case "3":
                    case "5":
                        await this.getContacts(this.campaign.contactsType, this.campaign.search)
                        break
                    case "1":
                        this.campaign.contacts = this.pickedContacts.map((item) => ({ contactId: item._id }))
                        break
                }

                if (!this.campaign.channelId)
                    this.campaign.channelId = channelId

                if (!this.campaign.sendingMessagesType || this.campaign.sendingMessagesType === "0")
                    this.campaign.sendingMessages = {
                        immediate: true
                    }

                this.campaign.send = send

                const resp = await api.createCampaign(channelId, this.campaign)

                if (resp.statusCode === 201) {
                    this.$emit("msg", {
                        text: "Campanha criada com sucesso!",
                        success: true
                    })

                    this.goToCampaigns()
                } else {
                    this.$emit("msg", {
                        text: "Ocorreu um erro!",
                    })
                }
                
                this.loading = false
            }
        },
        async getTemplatesByChannelId(channelId) {
            let templates = []

            const campaignTemplates = await api.getHsmTemplates(channelId, 'campaign')
            const attendanceTemplates = await api.getHsmTemplates(channelId, 'active_communication')
            
            if(campaignTemplates.statusCode === 200)
                templates = [...templates, ...campaignTemplates.templates.map(el => {el.type = 'campaign'; return el})]

            if(attendanceTemplates.statusCode === 200)
                templates = [...templates, ...attendanceTemplates.templates.map(el => {el.type = 'active_communication'; return el})]

            return templates
        },
        async getTemplates() {
            this.loadingTemplates = true
            let templates = []

            const promise = this.channels.map(async channel => {
                if (channel.apiType === 'gupshup' || channel.apiType === 'cloud') {
                    const channelModels = await this.getTemplatesByChannelId(channel._id)
                    templates.push(...channelModels)
                }
            })
            await Promise.all(promise)

            if (templates[0]?.components) {
                templates = templates.map(template => {
                    template.elementName = template.name
                    template.content = template.components.find(el => el.type === 'BODY')?.text
                    template.languageCode = template.language
                    template.templateType = template.components.find(el => el.type === 'HEADER')?.format || 'TEXT' 
                    template.header = template.components.find(el => el.type === 'HEADER')?.text
                    template.footer = template.components.find(el => el.type === 'FOOTER')?.text
                    template.buttons = template.components.find(el => el.type === 'BUTTONS')?.buttons
                    template.url = template.components.find(el => el.type === 'HEADER')?.example?.header_handle?.[0]

                    return template
                })
            }

            this.loadingTemplates = false

            this.templates = templates.sort((a,b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? 0 : -1)).filter(el => el.status === 'APPROVED')
        },
        async getSecondaryChannels() {
            const resp = await api.getSecondaryChannels(this.user.channelId || this.user.roleId)
            
            if(resp.statusCode === 200)
                return resp.channels

            return []
        },
        async getChannels() {
            if (this.user.channel)
                this.channels.push(this.user.channel)

            const secondaryChannels = await this.getSecondaryChannels()
            // this.channels.push(...secondaryChannels.filter((channel) => channel.apiType === 'gupshup' || channel.apiType === 'cloud'))
            this.channels.push(...secondaryChannels.filter((channel) => channel.apiType === 'gupshup'))
        },
        pickedTemplate(templateId) {
            if (templateId)
                this.setPreview(templateId)
        },
        async setPreview(templateId) {
            this.template = null
            this.loadingMessages = true
            
            const campaignTemplates = await api.getHsmTemplates(this.user.channelId || this.user.roleId, 'campaign')
            const attendanceTemplates = await api.getHsmTemplates(this.user.channelId || this.user.roleId, 'active_communication')

            if(campaignTemplates.statusCode === 200) {
                const template = this.getTemplateFromArray(campaignTemplates.templates, templateId)

                if (template)
                    this.template = { ...template, type: 'campaign' }
            }

            if(attendanceTemplates.statusCode === 200) {
                const template = this.getTemplateFromArray(attendanceTemplates.templates, templateId)

                if (template)
                    this.template = { ...template, type: 'active_communication' }
            }

            this.loadingMessages = false
        },
        getTemplateFromArray(templates, id) {
            let template = templates.find(el => el.id === id)

            if (template) {
                if (template.containerMeta) {           
                    const containerMeta = JSON.parse(template.containerMeta)

                    if (containerMeta) {
                        containerMeta.editTemplate = undefined
                        template = { ...template, ...containerMeta, content: containerMeta.data, example: containerMeta.sampleText, url: containerMeta.mediaUrl }
                    }
                } else if (template?.components) {
                    template.elementName = template.name
                    template.content = template.components.find(el => el.type === 'BODY')?.text
                    template.languageCode = template.language
                    template.templateType = template.components.find(el => el.type === 'HEADER')?.format || 'TEXT'
                    template.header = template.components.find(el => el.type === 'HEADER')?.text
                    template.footer = template.components.find(el => el.type === 'FOOTER')?.text
                    template.buttons = template.components.find(el => el.type === 'BUTTONS')?.buttons
                    template.url = template.components.find(el => el.type === 'HEADER')?.example?.header_handle?.[0]

                    if (template.templateType === 'BODY') {
                        template.templateType = 'TEXT'
                    }
                }

                if (!template.buttons)
                    template.buttons = []
            }

            return template
        },
        async getOperators() {
            const resp = await api.getOperators(this.user.channelId || this.user.roleId, true)

            if(resp.statusCode !== 200) {
                this.operators = []
                this.operators['total'] = 0
                return
            }

            this.operators = resp.operators.sort((a,b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0))
        },
        async getDepartments() {
            const resp = await api.getDepartments(this.user.channelId || this.user.roleId, true)
            
            if(resp.statusCode !== 200) {
                this.departments = []
                this.departments['total'] = 0
                return
            }

            this.departments = resp.departments
        },
        async getContacts(searchType, search = "") {
            const channelId = this.user.channelId || this.user.roleId
            let resp, filter
            switch (searchType) {
                case "1":
                    if (search) {
                        filter = { 
                            type: "keyword",
                            search
                        }
                        resp = await api.getAllContacts(channelId, filter)

                        if (resp.statusCode === 200) {
                            this.contacts = resp.contacts.map(el => {el.label = `${el.name} - ${el.number}`; return el})
                        } else {
                            this.contacts = []
                        }
                    }
                    break
                case "2":
                    filter = { 
                        type: "gender",
                        search
                    }
                    resp = await api.getAllContacts(channelId, filter)

                    if (resp.statusCode == 200) {
                        this.campaign.contacts = resp.contacts.map((item) => ({ contactId: item._id }))
                    } else {
                        this.$emit("msg", {
                            text: "Ocorreu um erro ao carregar os contatos!",
                            success: false
                        })
                    }
                    break
                case "3":
                    filter = {
                        type: "tag",
                        search
                    }
                    resp = await api.getAllContacts(channelId, filter)

                    if (resp.statusCode == 200) {
                        this.campaign.contacts = resp.contacts.map((item) => ({ contactId: item._id }))
                    } else {
                        this.$emit("msg", {
                            text: "Ocorreu um erro ao carregar os contatos!",
                            success: false
                        })
                    }
                    break
                case "5":
                    filter = {
                        type: "customerPortfolio",
                        search
                    }
                    resp = await api.getAllContacts(channelId, filter)

                    console.log('resp',resp)

                    if (resp.statusCode == 200) {
                        this.campaign.contacts = resp.contacts.map((item) => ({ contactId: item._id }))
                    } else {
                        this.$emit("msg", {
                            text: "Ocorreu um erro ao carregar os contatos!",
                            success: false
                        })
                    }
                    break
                default:
                    resp = await api.getAllContacts(channelId, {})

                    if (resp.statusCode == 200) {
                        this.campaign.contacts = resp.contacts.map((item) => ({ contactId: item._id }))
                    } else {
                        this.$emit("msg", {
                            text: "Ocorreu um erro ao carregar os contatos!",
                            success: false
                        })
                    }
            }
        },
        async getTags(page = 1) {
            this.loadingTags = true
            const resp = await api.getTags(this.user.channelId || this.user.roleId, page)
            this.loadingTags = false
            // console.log('resp',resp)
            if(!resp || resp.statusCode != 200) {
                this.tags = []
            } else {
                this.tags = resp.tags
            }
        },
        hidePickedContacts(options, _search) {
            return options.filter(option => !this.pickedContacts.includes(option));
        },
        selectContactSheet(file) {
            console.log('file',file)
            if(file) {
                if(file.size > 10000000) {
                    return this.$emit('msg', {
                        text: "O tamanho máximo permitido para envios é de 10 MB",
                        success: false
                    })
                } else {
                    const fileReader = new FileReader();
                    fileReader.onload = () => {
                        const arrayBuffer = fileReader.result
                        const data = new Uint8Array(arrayBuffer)
                        const workbook = XLSX.read(data, { type: 'array', cellText: false })
                        const firstSheetName = workbook.SheetNames[0]
                        const worksheet = workbook.Sheets[firstSheetName]

                        Object.keys(worksheet).forEach(function(s) {
                            if(worksheet[s].t === 'n') {
                                worksheet[s].z = '0'
                                worksheet[s].t = 's'
                            }
                        })
    
                        let jsonData = XLSX.utils.sheet_to_json(worksheet, { raw: false, dateNF: 'dd/mm/yyyy' })
                        try {
                            jsonData = jsonData.map((el)=>{
                                el = Object.fromEntries(
                                    Object.entries(el).map(([key, value]) => {
                                        key = key.trim().toLowerCase()
                                        if (key === 'telefone' && value) {
                                            if (value.includes(',') || value.includes('E+')) {
                                                throw new Error('Coluna "Telefone" contém notação científica')
                                            }
                                        }
                                        return [key, value]
                                    })
                                )
                                return el
                            })
                        } catch(error) {
                            console.error(error)

                            if (this.importContactsSuccess) {
                                this.importContactsSuccess = false
                            }

                            this.importingContactsSheet = false

                            this.errorMessage = error.message
                            return this.$emit('msg', {
                                text: error.message,
                                success: false
                            })
                        }
                        
                        console.log('sheetData', jsonData)

                        if(jsonData.length > 5000) {
                            return this.$emit('msg', {
                                text: "O máximo de contatos por planilha (5000) foi excedido!",
                                success: false
                            })
                        }

                        if (!jsonData.length) return
    
                        this.importContactsSheet(jsonData)
                    };
                    fileReader.readAsArrayBuffer(file)
                }
            }
        },
        async importContactsSheet(contactsSheetData) {
            if(!this.importingContactsSheet) {
                this.campaign.contacts = []
                this.importingContactsSheet = true
                this.hasImported = true
                this.importContactsSuccess = false

                const resp = await api.importContactsSheet({ data: contactsSheetData, channelId: this.user.channelId || this.user.roleId })
                this.importingContactsSheet = false
                console.log('resp',resp)
                let msg = null
                if(resp.statusCode == 200) {
                    const contacts = []

                    resp.failed.map(el => {
                        if (el.message === 'Contato já importado!' && el.contact?._id && !contacts.some(contact => contact._id === el.contact._id)) {
                            contacts.push({ contactId: el.contact._id })
                        }
                    })

                    resp.success.map(el => {
                        if (el.contact?._id) {
                            contacts.push({ contactId: el.contact._id })
                        }
                    })
                  
                    if (!contacts.length)
                        return msg = {
                            text: "Nenhum contato encontrado. Verifique se a estrutura da planilha está correta!",
                            success: false
                        }

                    this.importContactsSuccess = true
                    
                    this.campaign.contacts = contacts
                } else {
                    msg = {
                        text: resp.message || "Ocorreu um erro!",
                        success: false
                    }
                }

                if (msg)
                    this.$emit('msg',msg)
            }
        },
    }
}
</script>

<style>
    .customSelect .vs__dropdown-toggle {
        border-color: #eee;
        padding: 0.25em !important;
        border-radius: 0.375rem !important;
    }
    .customSelect .vs__search::placeholder {
        color: #666;
    }
</style>